<template>
  <v-container>
    <!-- notification area -->

    <v-alert
      v-model="error"
      transition="fade-transition"
      type="error"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="error = false">mdi-close</v-icon>
      <div class="mr-10 text-subtitle-1">
        {{ errorMessage }}
      </div>
    </v-alert>

    <v-alert
      v-model="submitted"
      transition="fade-transition"
      type="success"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="submitted = false">mdi-close</v-icon>
      <div class="mr-10 text-subtitle-1">Successfully Submitted</div>
    </v-alert>

    <!-- end of notification area -->

    <v-row v-if="loading || submitting" justify="center">
      <v-col xs="12" xl="8">
        <v-col>
          <h3 class="text-center">
            <v-progress-circular indeterminate class="ml-3" color="primary" />
            Please be patient, this may take a while.
          </h3>
        </v-col>
      </v-col>
    </v-row>

    <v-row v-if="!loading" v-show="!submitting" justify="center">
      <v-col xs="12" xl="8">
        <!-- header -->
        <v-row>
          <v-col>
            <h1>Funded Mortgage Entry</h1>

            <p class="grey--text">
              As an admin, you're in charge of accurately capturing each step
              within the mortgage process. Creating a new funded mortgage entry
              is a crucial part of this. Your meticulous recording of details
              guarantees precise record-keeping, contributing to transparent and
              efficient operations.
            </p>
          </v-col>
        </v-row>

        <div class="d-flex justify-center">
          <!-- Side Navigation -->
          <div class="mr-2">
            <div class="side-nav">
              SECTIONS:
              <v-list dense>
                <!-- Navigation Links -->
                <v-list-item
                  @click="
                    scrollToElement($refs.gen_info.$el);
                    if (!panels.includes(0)) panels.push(0);
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title> General Information </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="
                    scrollToElement($refs.customers.$el);
                    if (!panels.includes(1)) panels.push(1);
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title> Customers </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="
                    scrollToElement($refs.properties.$el);
                    if (!panels.includes(2)) panels.push(2);
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title> Properties </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="
                    scrollToElement($refs.liabilities.$el);
                    if (!panels.includes(3)) panels.push(3);
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title> Liabilities </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="
                    scrollToElement($refs.lawyers.$el);
                    if (!panels.includes(4)) panels.push(4);
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title> Lawyers </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="
                    scrollToElement($refs.loan_info.$el);
                    if (!panels.includes(5)) panels.push(5);
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title> Loan Information </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <!-- <v-list-item
                  @click="
                    $refs.status.$el.scrollIntoView({ behavior: 'smooth' });
                    if (!panels.includes(6)) panels.push(6);
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title> Status </v-list-item-title>
                  </v-list-item-content>
                </v-list-item> -->

                <v-list-item
                  @click="
                    scrollToElement($refs.holdbacks.$el);
                    if (!panels.includes(6)) panels.push(6);
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title> Holdbacks </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="
                    scrollToElement($refs.condition.$el);
                    if (!panels.includes(7)) panels.push(7);
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title> Condition </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="
                    scrollToElement($refs.lender_info.$el);
                    if (!panels.includes(8)) panels.push(8);
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title> Lender Information </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="
                    scrollToElement($refs.fees.$el);
                    if (!panels.includes(9)) panels.push(9);
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title> Fees </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="
                    scrollToElement($refs.prepaid_interest.$el);
                    if (!panels.includes(10)) panels.push(10);
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title> Prepaid Interest </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="
                    scrollToElement($refs.payment_method.$el);
                    if (!panels.includes(11)) panels.push(11);
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title> Payment Method </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="
                    scrollToElement($refs.referral_info.$el);
                    if (!panels.includes(12)) panels.push(12);
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      Referral Information
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="
                    scrollToElement($refs.closing_info.$el);
                    if (!panels.includes(13)) panels.push(13);
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      Closing/Funding Information
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="
                    scrollToElement($refs.reimbursement.$el);
                    if (!panels.includes(14)) panels.push(14);
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title> Reimbursement </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="
                    scrollToElement($refs.notes.$el);
                    if (!panels.includes(15)) panels.push(15);
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title> Notes </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  @click="
                    panels = [
                      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15
                    ]
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title class="green--text">
                      Expand All
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="panels = []">
                  <v-list-item-content>
                    <v-list-item-title class="red--text">
                      Collapse All
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </div>

          <div>
            <!-- Sections -->
            <v-expansion-panels v-model="panels" flat multiple>
              <!-- General Information -->
              <v-expansion-panel ref="gen_info" class="bordered">
                <v-expansion-panel-header>
                  <div>General Information</div>
                </v-expansion-panel-header>

                <v-divider />

                <v-expansion-panel-content>
                  <v-form ref="general_information">
                    <v-row class="mt-5">
                      <v-col>
                        <v-text-field
                          v-model="fund_mort.filogix_number"
                          ref="filogix_number"
                          label="Filogix App Number"
                          :rules="[rules.required]"
                        />
                      </v-col>

                      <v-col>
                        <v-select
                          :items="fiscal_year_items"
                          v-model="fund_mort.fiscal_year"
                          label="Fiscal year"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-menu
                          v-model="brokerage_agreement_signed_menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fund_mort.brokerage_agreement_signed"
                              label="Brokerage Agreement Signed"
                              outlined
                              hide-details="auto"
                              v-bind="attrs"
                              v-on="on"
                              :rules="[rules.date]"
                              hint="YYYY-MM-DD format"
                              append-icon="mdi-calendar-blank"
                            />
                          </template>
                          <v-date-picker
                            no-title
                            v-model="fund_mort.brokerage_agreement_signed"
                            @input="brokerage_agreement_signed_menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col>
                        <v-menu
                          v-model="commitment_signed_menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fund_mort.commitment_signed"
                              label="Commitment Signed"
                              outlined
                              hide-details="auto"
                              v-bind="attrs"
                              v-on="on"
                              :rules="[rules.date]"
                              hint="YYYY-MM-DD format"
                              append-icon="mdi-calendar-blank"
                            />
                          </template>
                          <v-date-picker
                            no-title
                            v-model="fund_mort.commitment_signed"
                            @input="commitment_signed_menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Customer -->
              <v-expansion-panel ref="customers" class="bordered">
                <v-expansion-panel-header>
                  <div>
                    Customers

                    <v-btn
                      icon
                      title="Sync Latest Data"
                      @click="read_deal_int_custs"
                      class="ml-2"
                    >
                      <v-icon> mdi-sync </v-icon>
                    </v-btn>

                    <span v-if="fund_mort.int_custs.length === 0">
                      (please create deal customers)
                    </span>
                  </div>
                </v-expansion-panel-header>

                <v-divider />

                <v-expansion-panel-content>
                  <div v-if="loading_int_cust" class="mt-5">
                    <h3 class="text-center grey--text">
                      <v-progress-circular indeterminate color="primary" />
                      Loading Customer Data
                    </h3>
                  </div>

                  <div v-if="!loading_int_cust" class="mt-5">
                    <p
                      class="grey--text"
                      v-if="fund_mort.int_custs.length !== 0"
                    >
                      You can only select one main applicant:
                    </p>
                    <v-expansion-panels multiple>
                      <v-expansion-panel
                        v-for="int_cust in fund_mort.int_custs"
                        :key="int_cust.id"
                        class="bordered"
                      >
                        <v-expansion-panel-header>
                          <div class="d-flex align-center">
                            <v-checkbox
                              v-model="int_cust.main_applicant"
                              class="mt-0"
                              hide-details="auto"
                              @change="
                                toggle_int_cust_main_applicant(
                                  int_cust.id,
                                  int_cust.main_applicant
                                )
                              "
                              dense
                            />
                            {{
                              viewed_as === "create"
                                ? int_cust.customer.first_name
                                : int_cust.cust_snap.first_name
                            }}
                            {{
                              viewed_as === "create"
                                ? int_cust.customer.middle_name
                                : int_cust.cust_snap.middle_name
                            }}
                            {{
                              viewed_as === "create"
                                ? int_cust.customer.last_name
                                : int_cust.cust_snap.last_name
                            }}
                            {{
                              int_cust.main_applicant ? " (Main Applicant)" : ""
                            }}
                          </div>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                          <v-simple-table>
                            <thead>
                              <th :width="250" />
                              <th />
                            </thead>

                            <tbody>
                              <tr>
                                <td colspan="2" class="grey--text">
                                  Contact Information:
                                </td>
                              </tr>

                              <tr>
                                <td>Email</td>

                                <td class="py-2">
                                  <div
                                    v-for="contact_info in viewed_as ===
                                    'create'
                                      ? int_cust.customer.contact_infos.filter(
                                          (el) => el.type === 'Email'
                                        )
                                      : int_cust.cust_snap.contact_infos.filter(
                                          (el) => el.type === 'Email'
                                        )"
                                    :key="contact_info.id"
                                  >
                                    {{ contact_info.email }}
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td>Home Phone Number</td>

                                <td class="py-2">
                                  <div
                                    v-for="contact_info in viewed_as ===
                                    'create'
                                      ? int_cust.customer.contact_infos.filter(
                                          (el) =>
                                            el.type === 'Home Phone Number'
                                        )
                                      : int_cust.cust_snap.contact_infos.filter(
                                          (el) =>
                                            el.type === 'Home Phone Number'
                                        )"
                                    :key="contact_info.id"
                                  >
                                    {{ contact_info.number }}
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td>Cellphone Number</td>

                                <td class="py-2">
                                  <div
                                    v-for="contact_info in viewed_as ===
                                    'create'
                                      ? int_cust.customer.contact_infos.filter(
                                          (el) => el.type === 'Cellphone Number'
                                        )
                                      : int_cust.cust_snap.contact_infos.filter(
                                          (el) => el.type === 'Cellphone Number'
                                        )"
                                    :key="contact_info.id"
                                  >
                                    {{ contact_info.number }}
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td>Work Phone Number</td>

                                <td class="py-2">
                                  <div
                                    v-for="contact_info in viewed_as ===
                                    'create'
                                      ? int_cust.customer.contact_infos.filter(
                                          (el) =>
                                            el.type === 'Work Phone Number'
                                        )
                                      : int_cust.cust_snap.contact_infos.filter(
                                          (el) =>
                                            el.type === 'Work Phone Number'
                                        )"
                                    :key="contact_info.id"
                                  >
                                    {{ contact_info.number }}
                                  </div>
                                </td>
                              </tr>

                              <tr v-if="!int_cust.main_applicant">
                                <td colspan="2" class="grey--text">
                                  Co-Applicant Information:
                                </td>
                              </tr>

                              <tr v-if="!int_cust.main_applicant">
                                <td>Co-Applicant Type</td>

                                <td>
                                  {{ int_cust.co_applicant_type }}
                                </td>
                              </tr>

                              <tr
                                v-if="
                                  int_cust.business_for_self ||
                                  int_cust.variable_income ||
                                  int_cust.poa
                                "
                              >
                                <td colspan="2" class="grey--text">
                                  Additional Consideration:
                                </td>
                              </tr>

                              <tr v-if="int_cust.business_for_self">
                                <td>
                                  Bussiness for Self:
                                  {{ int_cust.business_for_self | yes_no }}
                                </td>

                                <td>
                                  <v-expansion-panel class="my-2 bordered">
                                    <v-expansion-panel-header>
                                      Bussiness for Self Notes
                                    </v-expansion-panel-header>

                                    <v-divider />

                                    <v-expansion-panel-content class="mt-5">
                                      <p
                                        v-for="item in int_cust.business_self_notes"
                                        :key="item.id"
                                      >
                                        {{ item.note }}

                                        <span
                                          v-if="
                                            item.cust_emp_business_self
                                              .employer_name
                                          "
                                          class="grey--text"
                                        >
                                          - Employer:
                                          {{
                                            item.cust_emp_business_self
                                              .employer_name
                                          }}
                                        </span>
                                      </p>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </td>
                              </tr>

                              <tr v-if="int_cust.variable_income">
                                <td>
                                  Variable Income:
                                  {{ int_cust.variable_income | yes_no }}
                                </td>

                                <td>
                                  <v-expansion-panel class="my-2 bordered">
                                    <v-expansion-panel-header>
                                      Variable Income Notes
                                    </v-expansion-panel-header>

                                    <v-divider />

                                    <v-expansion-panel-content class="mt-5">
                                      <p
                                        v-for="item in int_cust.var_inc_notes"
                                        :key="item.id"
                                      >
                                        {{ item.note }}

                                        <span
                                          v-if="
                                            item.cust_emp_var_inc.employer_name
                                          "
                                          class="grey--text"
                                        >
                                          - Employer:
                                          {{
                                            item.cust_emp_var_inc.employer_name
                                          }}
                                        </span>
                                      </p>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </td>
                              </tr>

                              <tr v-if="int_cust.poa">
                                <td>
                                  Power of Attorney:
                                  {{ int_cust.poa | yes_no }}
                                </td>

                                <td>
                                  <v-expansion-panel class="my-2 bordered">
                                    <v-expansion-panel-header>
                                      Power of Attorney Notes
                                    </v-expansion-panel-header>

                                    <v-divider />

                                    <v-expansion-panel-content class="mt-5">
                                      <p
                                        v-for="item in int_cust.poa_notes"
                                        :key="item.id"
                                      >
                                        • {{ item.note }}
                                      </p>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Properties -->
              <v-expansion-panel ref="properties" class="bordered">
                <v-expansion-panel-header>
                  <div>Properties</div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-switch
                    v-if="fund_mort.int_props.length > 0"
                    v-model="fund_mort.is_blanket_mortgage"
                    label="Is this a blanket mortgage? (If yes, you can select multiple subject properties listed
                    below) "
                    hide-details="auto"
                    dense
                  />

                  <p
                    v-if="
                      fund_mort.int_props
                        ? fund_mort.int_props.length === 0
                        : false
                    "
                    class="d-flex justify-center grey--text text-subtitle-1"
                  >
                    No properties were found. Kindly add properties to customer.
                  </p>

                  <v-form ref="prop_info">
                    <v-card
                      v-for="item in fund_mort.int_props"
                      :key="item.id"
                      class="bordered mb-2 mt-5"
                    >
                      <v-card-title class="text-subtitle-2">
                        <div class="d-flex align-end">
                          <v-checkbox
                            v-model="item.subject"
                            @change="
                              !fund_mort.is_blanket_mortgage
                                ? toggle_int_prop_subject(item.id, item.subject)
                                : ''
                            "
                            dense
                            hide-details
                          />

                          <p class="my-0">
                            {{
                              viewed_as === "create"
                                ? item.prop.address.street_number
                                : item.prop_snap.address.street_number
                            }}
                            {{
                              viewed_as === "create"
                                ? item.prop.address.street_name
                                : item.prop_snap.address.street_name
                            }}
                            {{
                              viewed_as === "create"
                                ? item.prop.address.street_type
                                : item.prop_snap.address.street_type
                            }}
                            {{
                              viewed_as === "create"
                                ? item.prop.address.street_direction
                                : item.prop_snap.address.street_direction
                            }}
                            {{
                              viewed_as === "create"
                                ? item.prop.address.unit
                                : item.prop_snap.address.unit
                            }}
                            {{
                              viewed_as === "create"
                                ? item.prop.address.postal_code
                                : item.prop_snap.address.postal_code
                            }}
                            {{
                              viewed_as === "create"
                                ? item.prop.address.city
                                : item.prop_snap.address.city
                            }}
                            {{
                              viewed_as === "create"
                                ? item.prop.address.province
                                : item.prop_snap.address.province
                            }}
                          </p>

                          <span class="ml-2 grey--text" v-if="item.subject">
                            (Subject Property)
                          </span>
                        </div>
                      </v-card-title>

                      <v-card-text>
                        <v-simple-table dense>
                          <thead>
                            <th :width="250" />
                            <th />
                          </thead>

                          <tbody>
                            <tr>
                              <td>Property Type</td>

                              <td>
                                {{
                                  viewed_as === "create"
                                    ? item.prop.prop_style.property_type
                                    : item.prop_snap.prop_style.property_type
                                }}
                              </td>
                            </tr>

                            <tr>
                              <td>Purchase Price</td>

                              <td>
                                {{
                                  viewed_as === "create"
                                    ? item.prop.purchase_price
                                    : item.prop_snap.purchase_price | currency
                                }}
                              </td>
                            </tr>

                            <tr>
                              <td>Owned By</td>

                              <td>
                                <div
                                  v-for="(customer, index) in viewed_as ===
                                  'create'
                                    ? item.prop.customers
                                    : item.prop_snap.customers"
                                  :key="customer.id"
                                >
                                  {{ customer.first_name }}
                                  {{
                                    viewed_as === "create"
                                      ? index + 1 !== item.prop.customers.length
                                        ? "& "
                                        : ""
                                      : index + 1 !==
                                        item.prop_snap.customers.length
                                      ? "& "
                                      : ""
                                  }}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-card-text>

                      <v-divider />

                      <v-card-text>
                        <div>
                          <v-row>
                            <v-col cols="4">
                              <v-switch
                                v-model="item.prop_costs_incl_tds"
                                label="PITH Costs Included in TDS"
                                dense
                              />
                            </v-col>
                          </v-row>

                          <v-row v-if="viewed_as === 'create' ? item.prop.rental_property : item.prop_snap.rental_property">
                            <v-col cols="6">
                              <v-select
                                v-model="item.rental_income_offset"
                                label="Rental Income Offset"
                                :items="rental_income_offset_options"
                                hide-details="auto"
                              />
                            </v-col>

                            <v-col cols="4">
                              <v-text-field
                                v-if="
                                  item.rental_income_offset ===
                                    'Add Percentage to Gross Income' ||
                                  item.rental_income_offset ===
                                    'Reduce Rental exp., add bal to Gross Inc.'
                                "
                                v-model="item.rental_income_offset_percent"
                                label="Rental Offset"
                                type="number"
                                :rules="[rules.number, rules.required]"
                                hide-details="auto"
                                outlined
                              />
                            </v-col>
                          </v-row>
                        </div>
                      </v-card-text>
                    </v-card> 
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Liabilities -->
              <v-expansion-panel ref="liabilities" class="bordered">
                <v-expansion-panel-header>
                  <div>Liabilities</div>
                </v-expansion-panel-header>

                <v-divider />

                <v-expansion-panel-content>
                  <div v-if="loading_int_cust" class="mt-5">
                    <h3 class="text-center grey--text">
                      <v-progress-circular indeterminate color="primary" />
                      Loading Data
                    </h3>
                  </div>

                  <div v-if="!loading_int_cust">
                    <p class="mt-5 grey--text">Liability</p>

                    <v-card
                      v-for="int_cust in fund_mort.int_custs"
                      :key="int_cust.id"
                      class="bordered mb-2"
                    >
                      <v-card-title>
                        <div class="text-subtitle-2">
                          {{
                            viewed_as === "create"
                              ? int_cust.customer.first_name
                              : int_cust.cust_snap.first_name
                          }}
                          {{
                            viewed_as === "create"
                              ? int_cust.customer.middle_name
                              : int_cust.cust_snap.middle_name
                          }}
                          {{
                            viewed_as === "create"
                              ? int_cust.customer.last_name
                              : int_cust.cust_snap.last_name
                          }}
                        </div>
                      </v-card-title>

                      <v-divider />

                      <v-card-text>
                        <v-simple-table
                          v-if="
                            viewed_as === 'create'
                              ? int_cust.customer.cust_snaps[0].cust_liabs
                                  .length > 0
                              : int_cust.int_liabs.length > 0
                          "
                        >
                          <thead class="text-caption">
                            <tr>
                              <th class="py-2">Single or Joint</th>

                              <th>Type</th>

                              <th>Balance</th>

                              <th>Payment</th>

                              <th>Maturity Date</th>

                              <th :width="350">Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              v-for="item in viewed_as === 'create'
                                ? int_cust.customer.cust_snaps[0].cust_liabs
                                : int_cust.int_liabs"
                              :key="item.id"
                            >
                              <td>
                                {{
                                  viewed_as === "create"
                                    ? item.single
                                      ? "Joint"
                                      : "Single"
                                    : item.cust_liab.single
                                    ? "Joint"
                                    : "Single"
                                }}
                              </td>

                              <td>
                                {{
                                  viewed_as === "create"
                                    ? item.liability_type
                                    : item.cust_liab.liability_type
                                }}
                              </td>

                              <td>
                                {{
                                  viewed_as === "create"
                                    ? item.balance
                                    : item.cust_liab.balance | currency
                                }}
                              </td>

                              <td>
                                {{
                                  viewed_as === "create"
                                    ? item.payment
                                    : item.cust_liab.payment | currency
                                }}
                              </td>

                              <td>
                                {{
                                  viewed_as === "create"
                                    ? item.maturity_date
                                    : item.cust_liab.maturity_date | formatDate
                                }}
                              </td>

                              <td>
                                <v-select
                                  v-model="item.payoff"
                                  :items="payoff_items"
                                  solo
                                  outlined
                                  dense
                                  hide-details
                                />
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>

                        <p
                          v-if="
                            viewed_as === 'create'
                              ? int_cust.customer.cust_snaps[0].cust_liabs
                                  .length == 0
                              : int_cust.int_liabs.length == 0
                          "
                          class="mt-5 text-center"
                        >
                          (No Liabilities Found)
                        </p>
                      </v-card-text>
                    </v-card>

                    <p class="mt-5 grey--text">Existing Mortgage</p>

                    <v-card
                      v-for="int_prop in fund_mort.int_props"
                      :key="int_prop.id"
                      class="bordered mb-2"
                    >
                      <v-card-title>
                        <div class="text-subtitle-2">
                          {{
                            viewed_as === "create"
                              ? int_prop.prop.address.street_number
                              : int_prop.prop_snap.address.street_number
                          }}
                          {{
                            viewed_as === "create"
                              ? int_prop.prop.address.street_name
                              : int_prop.prop_snap.address.street_name
                          }}
                          {{
                            viewed_as === "create"
                              ? int_prop.prop.address.street_type
                              : int_prop.prop_snap.address.street_type
                          }}
                          {{
                            viewed_as === "create"
                              ? int_prop.prop.address.street_direction
                              : int_prop.prop_snap.address.street_direction
                          }}
                          {{
                            viewed_as === "create"
                              ? int_prop.prop.address.unit
                              : int_prop.prop_snap.address.unit
                          }}
                          {{
                            viewed_as === "create"
                              ? int_prop.prop.address.postal_code
                              : int_prop.prop_snap.address.postal_code
                          }}
                          {{
                            viewed_as === "create"
                              ? int_prop.prop.address.city
                              : int_prop.prop_snap.address.city
                          }}
                          {{
                            viewed_as === "create"
                              ? int_prop.prop.address.province
                              : int_prop.prop_snap.address.province
                          }}
                        </div>
                      </v-card-title>

                      <v-divider />

                      <v-card-text>
                        <p
                          class="grey--text"
                          v-if="
                            viewed_as === 'create'
                              ? int_prop.prop.prop_snaps[0].prop_morts.length >
                                0
                              : int_prop.int_mtgs.length > 0
                          "
                        >
                          Mortgages:
                        </p>

                        <p
                          v-if="
                            viewed_as === 'create'
                              ? int_prop.prop.prop_snaps[0].prop_morts
                                  .length === 0
                              : int_prop.int_mtgs.length === 0
                          "
                          class="text-center mt-5"
                        >
                          (No Existing Mortgages Found)
                        </p>

                        <v-simple-table
                          v-if="
                            viewed_as === 'create'
                              ? int_prop.prop.prop_snaps[0].prop_morts
                                  .length !== 0
                              : int_prop.int_mtgs.length !== 0
                          "
                        >
                          <thead class="text-caption">
                            <tr>
                              <th class="py-2">Position</th>

                              <th>Lender</th>

                              <th>Balance Remaining</th>

                              <th>Maturity Date</th>

                              <th :width="350">Payoff</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              v-for="item in viewed_as === 'create'
                                ? int_prop.prop.prop_snaps[0].prop_morts
                                : int_prop.int_mtgs"
                              :key="item.id"
                              class="bordered"
                            >
                              <td>
                                {{
                                  viewed_as === "create"
                                    ? item.position
                                    : item.prop_mort.position
                                }}
                              </td>

                              <td>
                                {{
                                  viewed_as === "create"
                                    ? item.lender
                                    : item.prop_mort.lender
                                }}
                              </td>

                              <td>
                                {{
                                  viewed_as === "create"
                                    ? item.balance_remaining
                                    : item.prop_mort.balance_remaining
                                      | currency
                                }}
                              </td>

                              <td>
                                {{
                                  viewed_as === "create"
                                    ? item.maturity_date
                                    : item.prop_mort.maturity_date | formatDate
                                }}
                              </td>

                              <td>
                                <v-select
                                  v-model="item.payoff"
                                  :items="payoff_items"
                                  solo
                                  outlined
                                  dense
                                  hide-details
                                />
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Lawyers -->
              <v-expansion-panel ref="lawyers" class="bordered">
                <v-expansion-panel-header>
                  <div>
                    Lawyers

                    <v-btn
                      icon
                      title="Sync Latest Data"
                      @click="read_all_company_lawyer"
                      class="ml-2"
                    >
                      <v-icon> mdi-sync </v-icon>
                    </v-btn>

                    <span
                      v-if="
                        fund_mort.borrower_lyrs.length == 0 &&
                        fund_mort.lender_lyrs.length == 0
                      "
                    >
                      ( Please Add Lawyers )
                    </span>
                  </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-alert
                    class="mt-5"
                    color="orange darken-3"
                    dense
                    transition="fade-transition"
                    outlined
                  >
                    <div class="d-flex align-center">
                      <v-icon color="orange darken-3">mdi-information-outline</v-icon>
                      <span class="ml-2">
                        Any additional or changes to the lawyer information will be saved only if the update button is clicked.
                      </span>
                    </div>
                  </v-alert>

                  <v-expansion-panels multiple>
                    <!-- BORROWER LAWYERS -->
                    <v-expansion-panel class="bordered">
                      <v-expansion-panel-header>
                        Borrowers
                      </v-expansion-panel-header>

                      <v-divider />

                      <v-expansion-panel-content>
                        <h3
                          v-if="
                            fund_mort.borrower_lyrs.filter((el) => !el.archived)
                              .length === 0
                          "
                          class="mt-10 grey--text text-center"
                        >
                          No Lawyers For The Borrower
                        </h3>

                        <v-expansion-panels class="mt-5" multiple>
                          <v-expansion-panel
                            class="bordered"
                            v-for="(
                              borrower_lyr, index
                            ) in fund_mort.borrower_lyrs.filter(
                              (el) => !el.archived
                            )"
                            :key="borrower_lyr.order"
                          >
                            <v-expansion-panel-header>
                              <div class="d-flex align-center">
                                <v-checkbox
                                  class="mt-0 pt-0"
                                  v-model="borrower_lyr.active"
                                  hide-details
                                />
                                {{
                                  borrower_lyr.user.companies
                                    .map((company) => company.name)
                                    .join(", ")
                                }}
                                -
                                {{ borrower_lyr.user.first_name }}
                                {{ borrower_lyr.user.last_name }}
                                {{ borrower_lyr.active ? " (Active)" : "" }}

                                <span class="ml-auto">
                                  <v-btn
                                    text
                                    color="red"
                                    @click.native.stop="
                                      handle_remove_lawyer(
                                        borrower_lyr.order,
                                        'borrower'
                                      )
                                    "
                                  >
                                    Remove
                                  </v-btn>
                                </span>
                              </div>
                            </v-expansion-panel-header>

                            <v-divider />

                            <v-expansion-panel-content>
                              <p class="grey--text mt-5">Contact Info:</p>
                              <v-simple-table dense>
                                <tbody>
                                  <tr>
                                    <td>Primary Email</td>

                                    <td>
                                      {{ borrower_lyr.user.email }}
                                    </td>
                                  </tr>

                                  <tr
                                    v-for="contact_info in borrower_lyr.user
                                      .contact_infos"
                                    :key="contact_info.id"
                                  >
                                    <td>{{ contact_info.type }}</td>

                                    <td>
                                      {{
                                        contact_info.type !== "Email"
                                          ? contact_info.number
                                          : contact_info.email
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </v-simple-table>

                              <p class="grey--text mt-5">Notes:</p>

                              <v-data-table
                                v-model="borrower_lyr.selected_notes"
                                :headers="lawyer_note_headers"
                                :items="borrower_lyr.notes"
                                item-key="order"
                                dense
                                show-select
                                @click:row="
                                  handle_update_lawyer_note(
                                    $event,
                                    index,
                                    'borrower'
                                  )
                                "
                              />

                              <v-btn
                                v-if="borrower_lyr.selected_notes.length > 0"
                                color="red"
                                @click="
                                  handle_delete_lawyer_note(
                                    borrower_lyr.order,
                                    'borrower'
                                  )
                                "
                              >
                                delete
                              </v-btn>

                              <!-- borrower lawyer note action -->
                              <div class="mt-5">
                                <v-btn
                                  v-if="
                                    !borrower_lyr.adding_note &&
                                    !borrower_lyr.updating_note
                                  "
                                  @click="borrower_lyr.adding_note = true"
                                >
                                  Add Note
                                </v-btn>

                                <v-btn
                                  v-if="
                                    !borrower_lyr.adding_note &&
                                    !borrower_lyr.updating_note
                                  "
                                  class="ml-5"
                                  @click="
                                    handle_archive_lawyer(
                                      borrower_lyr.order,
                                      'borrower'
                                    )
                                  "
                                >
                                  Archive
                                </v-btn>
                              </div>

                              <!-- add/update borrower lawyer note -->
                              <v-card
                                class="mt-5"
                                v-if="
                                  borrower_lyr.adding_note ||
                                  borrower_lyr.updating_note
                                "
                              >
                                <v-card-title class="text-body-2">
                                  {{
                                    borrower_lyr.adding_note ? "Add" : "Update"
                                  }}
                                  Note
                                </v-card-title>

                                <v-card-text>
                                  <v-textarea
                                    v-model="new_borrower_lawyer_note"
                                    label="Make a note"
                                    auto-grow
                                    outlined
                                  />
                                </v-card-text>

                                <v-divider />

                                <v-card-actions>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="
                                      borrower_lyr.adding_note
                                        ? handle_save_lyr_note(
                                            borrower_lyr.order,
                                            'borrower'
                                          )
                                        : handle_save_updated_lyr_note(
                                            borrower_lyr.order,
                                            'borrower'
                                          )
                                    "
                                  >
                                    save
                                  </v-btn>

                                  <v-btn
                                    text
                                    @click="
                                      borrower_lyr.adding_note = false;
                                      borrower_lyr.updating_note = false;
                                    "
                                  >
                                    cancel
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>

                        <!-- archived borrower lawyers -->
                        <v-expansion-panel
                          class="mt-5 bordered"
                          v-if="
                            fund_mort.borrower_lyrs.some((el) => el.archived)
                          "
                        >
                          <v-expansion-panel-header>
                            Archived Lawyers ({{
                              fund_mort.borrower_lyrs.filter(
                                (el) => el.archived
                              ).length
                            }})
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <h3
                              v-if="
                                fund_mort.borrower_lyrs.filter(
                                  (el) => el.archived
                                ).length === 0
                              "
                              class="mt-5 grey--text text-center"
                            >
                              No Archived Lawyers
                            </h3>

                            <v-expansion-panels class="mt-5" multiple>
                              <v-expansion-panel
                                class="bordered"
                                v-for="borrower_lyr in fund_mort.borrower_lyrs.filter(
                                  (el) => el.archived
                                )"
                                :key="borrower_lyr.order"
                              >
                                <v-expansion-panel-header>
                                  <div class="d-flex align-center">
                                    {{
                                      borrower_lyr.user.companies
                                        .map((company) => company.name)
                                        .join(", ")
                                    }}
                                    -
                                    {{ borrower_lyr.user.first_name }}
                                    {{ borrower_lyr.user.last_name }}
                                    {{ borrower_lyr.active ? " (Active)" : "" }}
                                  </div>
                                </v-expansion-panel-header>

                                <v-divider />

                                <v-expansion-panel-content>
                                  <v-simple-table class="mt-5" dense>
                                    <tbody>
                                      <tr>
                                        <td>Primary Email</td>

                                        <td>
                                          {{ borrower_lyr.user.email }}
                                        </td>
                                      </tr>

                                      <tr
                                        v-for="contact_info in borrower_lyr.user
                                          .contact_infos"
                                        :key="contact_info.id"
                                      >
                                        <td>{{ contact_info.type }}</td>

                                        <td>
                                          {{
                                            contact_info.type !== "Email"
                                              ? contact_info.number
                                              : contact_info.email
                                          }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </v-simple-table>

                                  <!-- list of notes -->

                                  <v-data-table
                                    class="mt-5"
                                    :headers="lawyer_note_headers"
                                    :items="borrower_lyr.notes"
                                    :items-per-page="5"
                                    dense
                                  />

                                  <div>
                                    <v-btn
                                      class="mt-5"
                                      @click="
                                        handle_archive_lawyer(
                                          borrower_lyr.order,
                                          'borrower'
                                        )
                                      "
                                    >
                                      unarchive
                                    </v-btn>
                                  </div>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <!-- borrower lawyer action -->
                        <div class="mt-5">
                          <v-btn
                            v-if="!adding_borrower_lawyer"
                            @click="adding_borrower_lawyer = true"
                          >
                            Add Lawyer
                          </v-btn>
                        </div>

                        <!-- add borrwer lawyer -->
                        <div v-if="adding_borrower_lawyer">
                          <v-text-field
                            v-model="search_borrower_lawyers"
                            label="Search"
                            single-line
                            hide-details
                          />

                          <v-data-table
                            :headers="lawyer_headers"
                            :items="
                              company_lawyers.filter((el) => {
                                return (
                                  !fund_mort.lender_lyrs
                                    .map((el) => el.user.id)
                                    .includes(el.id) &&
                                  !fund_mort.borrower_lyrs
                                    .map((el) => el.user.id)
                                    .includes(el.id)
                                );
                              })
                            "
                            :items-per-page="5"
                            :search="search_borrower_lawyers"
                            :custom-filter="customFilter"
                            @click:row="handle_add_lawyer($event, 'borrower')"
                          >
                            <template v-slot:item.companies="{ item }">
                              {{
                                item.companies
                                  .map((company) => company.name)
                                  .join(", ")
                              }}
                            </template>
                          </v-data-table>

                          <v-btn
                            class="mt-5"
                            @click="adding_borrower_lawyer = false"
                          >
                            cancel
                          </v-btn>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- LENDER LAWYERS -->
                    <v-expansion-panel class="bordered">
                      <v-expansion-panel-header>
                        Lender
                      </v-expansion-panel-header>

                      <v-divider />

                      <v-expansion-panel-content>
                        <h3
                          v-if="
                            fund_mort.lender_lyrs.filter((el) => !el.archived)
                              .length === 0
                          "
                          class="mt-10 grey--text text-center"
                        >
                          No Lawyers For The Lender
                        </h3>

                        <v-expansion-panels class="mt-5" multiple>
                          <v-expansion-panel
                            class="bordered"
                            v-for="(
                              lender_lyr, index
                            ) in fund_mort.lender_lyrs.filter(
                              (el) => !el.archived
                            )"
                            :key="lender_lyr.order"
                          >
                            <v-expansion-panel-header>
                              <div class="d-flex align-center">
                                <v-checkbox
                                  class="mt-0 pt-0"
                                  v-model="lender_lyr.active"
                                  hide-details
                                />
                                {{
                                  lender_lyr.user.companies
                                    .map((company) => company.name)
                                    .join(", ")
                                }}
                                -
                                {{ lender_lyr.user.first_name }}
                                {{ lender_lyr.user.last_name }}
                                {{ lender_lyr.active ? " (Active)" : "" }}

                                <span class="ml-auto">
                                  <v-btn
                                    text
                                    color="red"
                                    @click.native.stop="
                                      handle_remove_lawyer(
                                        lender_lyr.order,
                                        'lender'
                                      )
                                    "
                                  >
                                    Remove
                                  </v-btn>
                                </span>
                              </div>
                            </v-expansion-panel-header>

                            <v-divider />

                            <v-expansion-panel-content>
                              <p class="grey--text mt-5">Contact Info:</p>
                              <v-simple-table dense>
                                <tbody>
                                  <tr>
                                    <td>Primary Email</td>

                                    <td>
                                      {{ lender_lyr.user.email }}
                                    </td>
                                  </tr>

                                  <tr
                                    v-for="contact_info in lender_lyr.user
                                      .contact_infos"
                                    :key="contact_info.id"
                                  >
                                    <td>{{ contact_info.type }}</td>

                                    <td>
                                      {{
                                        contact_info.type !== "Email"
                                          ? contact_info.number
                                          : contact_info.email
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </v-simple-table>

                              <p class="grey--text mt-5">Notes:</p>

                              <v-data-table
                                v-model="lender_lyr.selected_notes"
                                :headers="lawyer_note_headers"
                                :items="lender_lyr.notes"
                                item-key="order"
                                dense
                                show-select
                                @click:row="
                                  handle_update_lawyer_note(
                                    $event,
                                    index,
                                    'lender'
                                  )
                                "
                              />

                              <v-btn
                                v-if="lender_lyr.selected_notes.length > 0"
                                color="red"
                                @click="
                                  handle_delete_lawyer_note(
                                    lender_lyr.order,
                                    'lender'
                                  )
                                "
                              >
                                delete
                              </v-btn>

                              <!-- lender lawyer note action -->
                              <div class="mt-5">
                                <v-btn
                                  v-if="
                                    !lender_lyr.adding_note &&
                                    !lender_lyr.updating_note
                                  "
                                  @click="lender_lyr.adding_note = true"
                                >
                                  Add Note
                                </v-btn>

                                <v-btn
                                  v-if="
                                    !lender_lyr.adding_note &&
                                    !lender_lyr.updating_note
                                  "
                                  class="ml-5"
                                  @click="
                                    handle_archive_lawyer(
                                      lender_lyr.order,
                                      'lender'
                                    )
                                  "
                                >
                                  Archive
                                </v-btn>
                              </div>

                              <!-- add/update lender lawyer note -->
                              <v-card
                                class="mt-5"
                                v-if="
                                  lender_lyr.adding_note ||
                                  lender_lyr.updating_note
                                "
                              >
                                <v-card-title class="text-body-2">
                                  {{
                                    lender_lyr.adding_note ? "Add" : "Update"
                                  }}
                                  Note
                                </v-card-title>

                                <v-card-text>
                                  <v-textarea
                                    v-model="new_lender_lawyer_note"
                                    label="Make a note"
                                    auto-grow
                                    outlined
                                  />
                                </v-card-text>

                                <v-divider />

                                <v-card-actions>
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="
                                      lender_lyr.adding_note
                                        ? handle_save_lyr_note(
                                            lender_lyr.order,
                                            'lender'
                                          )
                                        : handle_save_updated_lyr_note(
                                            lender_lyr.order,
                                            'lender'
                                          )
                                    "
                                  >
                                    save
                                  </v-btn>

                                  <v-btn
                                    text
                                    @click="
                                      lender_lyr.adding_note = false;
                                      lender_lyr.updating_note = false;
                                    "
                                  >
                                    cancel
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>

                        <!-- archived lender lawyers -->
                        <v-expansion-panel
                          class="mt-5 bordered"
                          v-if="fund_mort.lender_lyrs.some((el) => el.archived)"
                        >
                          <v-expansion-panel-header>
                            Archived Lawyers ({{
                              fund_mort.lender_lyrs.filter((el) => el.archived)
                                .length
                            }})
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <h3
                              v-if="
                                fund_mort.lender_lyrs.filter(
                                  (el) => el.archived
                                ).length === 0
                              "
                              class="mt-5 grey--text text-center"
                            >
                              No Archived Lawyers
                            </h3>

                            <v-expansion-panels class="mt-5" multiple>
                              <v-expansion-panel
                                class="bordered"
                                v-for="lender_lyr in fund_mort.lender_lyrs.filter(
                                  (el) => el.archived
                                )"
                                :key="lender_lyr.order"
                              >
                                <v-expansion-panel-header>
                                  <div class="d-flex align-center">
                                    {{
                                      lender_lyr.user.companies
                                        .map((company) => company.name)
                                        .join(", ")
                                    }}
                                    -
                                    {{ lender_lyr.user.first_name }}
                                    {{ lender_lyr.user.last_name }}
                                    {{ lender_lyr.active ? " (Active)" : "" }}
                                  </div>
                                </v-expansion-panel-header>

                                <v-divider />

                                <v-expansion-panel-content>
                                  <v-simple-table class="mt-5" dense>
                                    <tbody>
                                      <tr>
                                        <td>Primary Email</td>

                                        <td>
                                          {{ lender_lyr.user.email }}
                                        </td>
                                      </tr>

                                      <tr
                                        v-for="contact_info in lender_lyr.user
                                          .contact_infos"
                                        :key="contact_info.id"
                                      >
                                        <td>{{ contact_info.type }}</td>

                                        <td>
                                          {{
                                            contact_info.type !== "Email"
                                              ? contact_info.number
                                              : contact_info.email
                                          }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </v-simple-table>

                                  <!-- list of notes -->

                                  <v-data-table
                                    class="mt-5"
                                    :headers="lawyer_note_headers"
                                    :items="lender_lyr.notes"
                                    :items-per-page="5"
                                    dense
                                  />

                                  <div>
                                    <v-btn
                                      class="mt-5"
                                      @click="
                                        handle_archive_lawyer(
                                          lender_lyr.order,
                                          'lender'
                                        )
                                      "
                                    >
                                      unarchive
                                    </v-btn>
                                  </div>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <!-- lender lawyer action -->
                        <div class="mt-5">
                          <v-btn
                            v-if="!adding_lender_lawyer"
                            @click="adding_lender_lawyer = true"
                          >
                            Add Lawyer
                          </v-btn>
                        </div>

                        <!-- add borrwer lawyer -->
                        <div v-if="adding_lender_lawyer">
                          <v-text-field
                            v-model="search_lender_lawyers"
                            label="Search"
                            single-line
                            hide-details
                          />

                          <v-data-table
                            :headers="lawyer_headers"
                            :items="
                              company_lawyers.filter((el) => {
                                return (
                                  !fund_mort.lender_lyrs
                                    .map((el) => el.user.id)
                                    .includes(el.id) &&
                                  !fund_mort.borrower_lyrs
                                    .map((el) => el.user.id)
                                    .includes(el.id)
                                );
                              })
                            "
                            :items-per-page="5"
                            :search="search_lender_lawyers"
                            :custom-filter="customFilter"
                            @click:row="handle_add_lawyer($event, 'lender')"
                          >
                            <template v-slot:item.companies="{ item }">
                              {{
                                item.companies
                                  .map((company) => company.name)
                                  .join(", ")
                              }}
                            </template>
                          </v-data-table>

                          <v-btn
                            class="mt-5"
                            @click="adding_lender_lawyer = false"
                          >
                            cancel
                          </v-btn>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Loan Information -->
              <v-expansion-panel ref="loan_info" class="bordered">
                <v-expansion-panel-header>
                  <div>Loan Information</div>
                </v-expansion-panel-header>

                <v-divider />

                <v-expansion-panel-content>
                  <v-form ref="loan_information">
                    <v-row class="mt-5">
                      <v-col>
                        <v-text-field
                          v-model="fund_mort.value"
                          label="Value"
                          hide-details="auto"
                          :rules="[rules.required]"
                          @blur="
                            fund_mort.value = formatCurrency(fund_mort.value)
                          "
                        />
                      </v-col>

                      <v-col>
                        <v-text-field
                          v-model="fund_mort.ltv"
                          label="LTV (Loan to Value ratio) "
                          type="number"
                          :rules="[rules.number]"
                          @blur="
                            fund_mort.ltv === ''
                              ? (fund_mort.ltv = null)
                              : (fund_mort.ltv = fund_mort.ltv)
                          "
                        />
                      </v-col>

                      <v-col>
                        <v-text-field
                          v-model="fund_mort.mortgage_position"
                          label="Mortgage Position"
                          type="number"
                          :rules="[rules.number]"
                          @blur="
                            fund_mort.mortgage_position === ''
                              ? (fund_mort.mortgage_position = null)
                              : (fund_mort.mortgage_position = fund_mort.mortgage_position)
                          "
                        />
                      </v-col>
                    </v-row>

                    <v-divider class="my-5" />

                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="fund_mort.term_months"
                          label="Term (months)"
                          type="number"
                          :rules="[rules.number]"
                          @blur="
                            fund_mort.term_months === ''
                              ? (fund_mort.term_months = null)
                              : (fund_mort.term_months = fund_mort.term_months)
                          "
                        />
                      </v-col>

                      <v-col>
                        <v-text-field
                          v-model="fund_mort.interest_rate"
                          label="Interest Rate (%)"
                          type="number"
                          :rules="[rules.number]"
                          @blur="
                            fund_mort.interest_rate === ''
                              ? (fund_mort.interest_rate = null)
                              : (fund_mort.interest_rate = fund_mort.interest_rate)
                          "
                        />
                      </v-col>

                      <v-col>
                        <v-text-field
                          v-model="fund_mort.interest_amount"
                          label="Interest Total Amount Annual"
                          @blur="
                            fund_mort.interest_amount = formatCurrency(
                              fund_mort.interest_amount
                            )
                          "
                        />
                      </v-col>
                    </v-row>

                    <v-divider class="my-5" />

                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="fund_mort.gds"
                          label="GDS (Gross Debt Service ratio)"
                          type="number"
                          hide-details="auto"
                          :rules="[rules.number]"
                          @blur="
                            fund_mort.gds === ''
                              ? (fund_mort.gds = null)
                              : (fund_mort.gds = fund_mort.gds)
                          "
                        />
                      </v-col>

                      <v-col>
                        <v-text-field
                          v-model="fund_mort.tds"
                          label="TDS (Total Debt Service ratio)"
                          type="number"
                          hide-details="auto"
                          :rules="[rules.number]"
                          @blur="
                            fund_mort.tds === ''
                              ? (fund_mort.tds = null)
                              : (fund_mort.tds = fund_mort.tds)
                          "
                        />
                      </v-col>
                    </v-row>

                    <v-divider class="my-5" />

                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="fund_mort.apr_in_disclosure"
                          label="APR in Disclosure (%)"
                          type="number"
                          :rules="[rules.number]"
                          @blur="
                            fund_mort.apr_in_disclosure === ''
                              ? (fund_mort.apr_in_disclosure = null)
                              : (fund_mort.apr_in_disclosure = fund_mort.apr_in_disclosure)
                          "
                        />
                      </v-col>

                      <v-col>
                        <v-text-field
                          v-model="fund_mort.apr_in_formula"
                          label="APR in Formula (%)"
                          type="number"
                          :rules="[rules.number]"
                          @blur="
                            fund_mort.apr_in_formula === ''
                              ? (fund_mort.apr_in_formula = null)
                              : (fund_mort.apr_in_formula = fund_mort.apr_in_formula)
                          "
                        />
                      </v-col>
                    </v-row>

                    <v-divider class="my-5" />

                    <v-row>
                      <v-col>
                        <v-menu
                          v-model="mort_start_date_menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fund_mort.mort_start_date"
                              label="Mortgage Start Date"
                              outlined
                              hide-details="auto"
                              v-bind="attrs"
                              v-on="on"
                              :rules="[rules.date]"
                              hint="YYYY-MM-DD format"
                              append-icon="mdi-calendar-blank"
                            />
                          </template>
                          <v-date-picker
                            no-title
                            v-model="fund_mort.mort_start_date"
                            @input="mort_start_date_menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col>
                        <v-menu
                          v-model="mort_maturity_date_menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fund_mort.mort_maturity_date"
                              label="Mortgage Maturity Date"
                              outlined
                              hide-details="auto"
                              v-bind="attrs"
                              v-on="on"
                              :rules="[rules.date]"
                              hint="YYYY-MM-DD format"
                              append-icon="mdi-calendar-blank"
                            />
                          </template>
                          <v-date-picker
                            no-title
                            v-model="fund_mort.mort_maturity_date"
                            @input="mort_maturity_date_menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-menu
                          v-model="first_payment_date_menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fund_mort.first_payment_date"
                              label="First Payment Date"
                              outlined
                              hide-details="auto"
                              v-bind="attrs"
                              v-on="on"
                              :rules="[rules.date]"
                              hint="YYYY-MM-DD format"
                              append-icon="mdi-calendar-blank"
                            />
                          </template>
                          <v-date-picker
                            no-title
                            v-model="fund_mort.first_payment_date"
                            @input="first_payment_date_menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col>
                        <v-menu
                          v-model="last_payment_date_menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fund_mort.last_payment_date"
                              label="Last Payment Date"
                              outlined
                              hide-details="auto"
                              v-bind="attrs"
                              v-on="on"
                              :rules="[rules.date]"
                              hint="YYYY-MM-DD format"
                              append-icon="mdi-calendar-blank"
                            />
                          </template>
                          <v-date-picker
                            no-title
                            v-model="fund_mort.last_payment_date"
                            @input="last_payment_date_menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="fund_mort.interest_adjustment"
                          label="Interest Adjustment"
                          type="number"
                          :rules="[rules.number]"
                          @blur="
                            fund_mort.interest_adjustment === ''
                              ? (fund_mort.interest_adjustment = null)
                              : (fund_mort.interest_adjustment = fund_mort.interest_adjustment)
                          "
                        />
                      </v-col>

                      <v-col>
                        <v-menu
                          v-model="interest_adjustment_date_menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fund_mort.interest_adjustment_date"
                              label="Interest adjustment date"
                              outlined
                              hide-details="auto"
                              v-bind="attrs"
                              v-on="on"
                              :rules="[rules.date]"
                              hint="YYYY-MM-DD format"
                              append-icon="mdi-calendar-blank"
                            />
                          </template>
                          <v-date-picker
                            no-title
                            v-model="fund_mort.interest_adjustment_date"
                            @input="interest_adjustment_date_menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <v-divider class="my-5" />

                    <v-row>
                      <v-col>
                        <v-select
                          v-model="fund_mort.loan_type"
                          :items="loan_type_items"
                          label="Loan Type"
                        />
                      </v-col>

                      <v-col>
                        <v-select
                          v-model="fund_mort.mortgage_type"
                          :items="mortgage_type_items"
                          label="Mortgage Type"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-select
                          v-model="fund_mort.ratio"
                          :items="ratio_items"
                          label="Ratio (Conventional (less or equal to 80% LTV))"
                        />
                      </v-col>

                      <v-col>
                        <v-select
                          v-model="fund_mort.classification"
                          :items="classification_items"
                          label="Classification"
                        />
                      </v-col>
                    </v-row>

                    <v-divider class="my-5" />

                    <v-row>
                      <v-col>
                        <v-switch
                          v-model="fund_mort.alert"
                          label="Alert if commitment date is expired?"
                        />
                      </v-col>

                      <v-col>
                        <v-switch
                          label="Include in TDS"
                          v-model="fund_mort.include_in_tds"
                          dense
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-select
                          v-model="fund_mort.amortization_interest"
                          :items="amortization_interest_items"
                          label="Amortization or Interest?"
                        />
                      </v-col>

                      <v-col
                        v-if="
                          fund_mort.amortization_interest === 'amortization'
                        "
                      >
                        <v-text-field
                          v-model="fund_mort.amortization_months"
                          label="How many months amortization?"
                          type="number"
                          :rules="[rules.number]"
                          @blur="
                            fund_mort.amortization_months === ''
                              ? (fund_mort.amortization_months = null)
                              : (fund_mort.amortization_months = fund_mort.amortization_months)
                          "
                        />
                      </v-col>
                    </v-row>

                    <v-divider class="my-5" />
                    
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="fund_mort.nsf_fee_amount"
                          label="NSF Fee Amount"
                          hide-details="auto"
                          @blur="
                            fund_mort.nsf_fee_amount = formatCurrency(fund_mort.nsf_fee_amount)
                          "
                        />
                      </v-col>
                      <v-col></v-col>
                    </v-row>  

                    <!-- nsf fee notes display -->
                    <v-row>
                      <v-col>
                        <v-alert
                          color="orange darken-3"
                          dense
                          transition="fade-transition"
                          outlined
                        >
                          <div class="d-flex align-center">
                            <v-icon color="orange darken-3">mdi-information-outline</v-icon>
                            <span class="ml-2">
                              Additional NSF fee notes will be saved only if the update button is clicked.
                            </span>
                          </div>
                        </v-alert>

                        <v-data-table
                          v-if="fund_mort.nsf_fee_notes.length > 0"
                          :headers="nsf_fee_note_header"
                          :items="fund_mort.nsf_fee_notes"
                          :items-per-page="5"
                          item-key="order"
                          hide-default-footer
                          v-model="selected_nsf_fee_note"
                          show-select
                          @click:row="handle_updating_nsf_fee_note"
                        />

                        <v-btn
                          v-if="selected_nsf_fee_note.length > 0"
                          color="error"
                          class="mt-2"
                          @click="handle_delete_nsf_fee_note"
                        >
                          delete
                        </v-btn>
                      </v-col>
                    </v-row>

                    <!-- adding nfs fee notes -->
                    <v-row>
                      <v-col>
                        <v-btn
                          v-if="!adding_nsf_fee_note && !updating_nsf_fee_note"
                          @click="adding_nsf_fee_note = true"
                        >
                          Add Notes
                        </v-btn>

                        <v-card
                          v-if="adding_nsf_fee_note || updating_nsf_fee_note"
                        >
                          <v-card-title>
                            {{ adding_nsf_fee_note ? "Add" : "Update" }} Note
                            <v-icon class="ml-2">mdi-note-outline</v-icon>
                          </v-card-title>

                          <v-card-text>
                            <v-textarea
                              v-model="new_nsf_fee_note"
                              label="Make a note"
                              auto-grow
                              outlined
                            />
                          </v-card-text>

                          <v-divider />

                          <v-card-actions>
                            <v-btn
                              v-if="adding_nsf_fee_note"
                              text
                              color="primary"
                              @click="handle_save_nsf_fee_note"
                            >
                              save
                            </v-btn>

                            <v-btn
                              v-if="updating_nsf_fee_note"
                              text
                              color="primary"
                              @click="handle_update_nsf_fee_note"
                            >
                              update
                            </v-btn>

                            <v-btn
                              text
                              @click="
                                adding_nsf_fee_note = false;
                                updating_nsf_fee_note = false;
                              "
                            >
                              cancel
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-divider class="my-5" />
                    
                    <v-row>
                      <v-col>
                        <v-select
                          v-model="fund_mort.prepayment_penalty"
                          :items="prepayment_penalty_items"
                          label="Prepayment Penalty"
                        />
                      </v-col>

                      <v-col>
                        <v-text-field
                          v-if="fund_mort.prepayment_penalty === 'OPEN after [X] Months'"
                          v-model="fund_mort.prepayment_penalty_number_of_months"
                          label="Number of Months"
                          :rules="[rules.number]"
                          type="number"
                          outlined
                          hide-details="auto"
                        />
                      </v-col>
                    </v-row>

                    <v-divider class="my-5" />

                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="fund_mort.payment_amount"
                          label="Payment Amount"
                          hide-details="auto"
                          outlined
                          @blur="
                            fund_mort.payment_amount = formatCurrency(fund_mort.payment_amount)
                          "
                        />
                      </v-col>

                      <v-col>
                        <v-select
                          v-model="fund_mort.payment_frequency"
                          :items="payment_frequency_items"
                          label="Payment Frequency"
                          hide-details="auto"
                        />
                      </v-col>

                      <v-col>
                        <v-text-field
                          v-if="fund_mort.payment_frequency === 'Other'"
                          v-model="fund_mort.specify_payment_frequency"
                          label="Specify Payment Frequency"
                          hide-details="auto"
                          outlined
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Status -->
              <!-- <v-expansion-panel ref="status" class="bordered">
                <v-expansion-panel-header>
                  <div>Status</div>
                </v-expansion-panel-header>

                <v-divider />

                <v-expansion-panel-content>
                  <v-form v-model="status_is_valid">
                    <v-row class="mt-5">
                      <v-col>
                        <v-select
                          v-model="fund_mort.status"
                          :items="status_items"
                          label="Select Status "
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>

                    <v-divider
                      v-if="fund_mort.status_notes.length > 0"
                      class="my-5"
                    />

                    <v-row>
                      <v-col>
                        <v-data-table
                          v-if="fund_mort.status_notes.length > 0"
                          :headers="status_note_header"
                          :items="fund_mort.status_notes"
                          :items-per-page="5"
                          item-key="order"
                          hide-default-footer
                          v-model="selected_status_note"
                          show-select
                          @click:row="handle_updating_status_note"
                        />

                        <v-btn
                          v-if="selected_status_note.length > 0"
                          color="error"
                          class="mt-2"
                          @click="handle_delete_status_note"
                        >
                          delete
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row v-if="fund_mort.status !== null">
                      <v-col>
                        <v-btn
                          v-if="!adding_status_note && !updating_status_note"
                          @click="adding_status_note = true"
                        >
                          Add Notes
                        </v-btn>

                        <v-card
                          v-if="adding_status_note || updating_status_note"
                        >
                          <v-card-title>
                            {{ adding_status_note ? "Add" : "Update" }} Note
                            <v-icon class="ml-2">mdi-note-outline</v-icon>
                          </v-card-title>

                          <v-card-text>
                            <v-textarea
                              v-model="new_status_note"
                              label="Make a note"
                              auto-grow
                              outlined
                            />
                          </v-card-text>

                          <v-divider />

                          <v-card-actions>
                            <v-btn
                              v-if="adding_status_note"
                              text
                              color="primary"
                              @click="handle_save_status_note"
                            >
                              save
                            </v-btn>

                            <v-btn
                              v-if="updating_status_note"
                              text
                              color="primary"
                              @click="handle_update_status_note"
                            >
                              update
                            </v-btn>

                            <v-btn
                              text
                              @click="
                                adding_status_note = false;
                                updating_status_note = false;
                              "
                            >
                              cancel
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel> -->

              <!-- Holdbacks -->
              <v-expansion-panel ref="holdbacks" class="bordered">
                <v-expansion-panel-header> Holdbacks </v-expansion-panel-header>

                <v-divider />

                <v-expansion-panel-content>
                  <v-row>
                    <v-col>
                      <v-alert
                        class="mt-5"
                        color="orange darken-3"
                        dense
                        transition="fade-transition"
                        outlined
                      >
                        <div class="d-flex align-center">
                          <v-icon color="orange darken-3">mdi-information-outline</v-icon>
                          <span class="ml-2">
                            Any additional or changes in Holdbacks will be saved only if the update button is clicked.
                          </span>
                        </div>
                      </v-alert>

                      <v-switch
                        v-model="fund_mort.with_holdbacks"
                        label="Was there holdbacks?"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="fund_mort.with_holdbacks">
                    <v-col>
                      <v-data-table
                        v-if="fund_mort.holdbacks.length > 0"
                        :headers="holdback_header"
                        :items="fund_mort.holdbacks"
                        :items-per-page="5"
                        item-key="order"
                        hide-default-footer
                        v-model="selected_holdback"
                        show-select
                        @click:row="handle_updating_holdback"
                      />

                      <v-btn
                        v-if="selected_holdback.length > 0"
                        color="error"
                        class="mt-2"
                        @click="handle_delete_holdback"
                      >
                        delete
                      </v-btn>

                      <br />

                      <v-btn
                        class="mt-2"
                        v-if="!adding_holdback && !updating_holdback"
                        @click="adding_holdback = true"
                      >
                        add holdback
                      </v-btn>

                      <v-form
                        v-if="adding_holdback || updating_holdback"
                        v-model="holdback_is_valid"
                      >
                        <v-card>
                          <v-card-title class="text-body-2">
                            {{ adding_holdback ? "Adding" : "Updating" }}
                            Holdback
                          </v-card-title>

                          <v-divider />

                          <v-card-text>
                            <v-row class="mt-5">
                              <v-col cols="4">
                                <v-text-field
                                  v-model="new_holdback_amount"
                                  label="Amount"
                                  outlined
                                  hide-details="auto"
                                  :rules="[rules.required]"
                                  @blur="
                                    new_holdback_amount =
                                      formatCurrency(new_holdback_amount)
                                  "
                                />
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-textarea
                                  v-model="new_holdback_description"
                                  label="Description"
                                  outlined
                                  hide-details="auto"
                                  auto-grow
                                  :rules="[rules.required]"
                                />
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-textarea
                                  v-model="new_holdback_note"
                                  label="Notes"
                                  auto-grow
                                  outlined
                                  hide-details="auto"
                                  :rules="[rules.required]"
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>

                          <v-card-actions>
                            <v-btn
                              color="primary"
                              :disabled="!holdback_is_valid"
                              @click="
                                adding_holdback
                                  ? handle_save_holdback()
                                  : handle_update_holdback()
                              "
                            >
                              save
                            </v-btn>

                            <v-btn
                              @click="
                                adding_holdback = false;
                                updating_holdback = false;
                              "
                            >
                              cancel
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Condition -->
              <v-expansion-panel ref="condition" class="bordered">
                <v-expansion-panel-header> Condition </v-expansion-panel-header>

                <v-divider />

                <v-expansion-panel-content>
                  <v-alert
                    class="mt-5"
                    color="orange darken-3"
                    dense
                    transition="fade-transition"
                    outlined
                  >
                    <div class="d-flex align-center">
                      <v-icon color="orange darken-3">mdi-information-outline</v-icon>
                      <span class="ml-2">
                        Any additional conditions or changes will be saved only if the update button is clicked.
                      </span>
                    </div>
                  </v-alert>

                  <v-row
                    v-if="fund_mort.fund_mort_conds.length > 0"
                    class="mt-5"
                  >
                    <v-col>
                      <v-data-table
                        :headers="condition_header"
                        :items="fund_mort.fund_mort_conds"
                        :items-per-page="5"
                        item-key="order"
                        hide-default-footer
                        v-model="selected_condition"
                        show-select
                        @click:row="handle_updating_condition"
                      />

                      <v-btn
                        v-if="selected_condition.length > 0"
                        color="error"
                        class="mt-2"
                        @click="handle_delete_condition"
                      >
                        delete
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-btn
                        class="mt-5"
                        v-if="!adding_condition && !updating_condition"
                        @click="adding_condition = true"
                      >
                        Add Condition
                      </v-btn>

                      <v-card
                        class="mt-5"
                        v-if="adding_condition || updating_condition"
                      >
                        <v-card-title>
                          {{ adding_condition ? "Add" : "Update" }} Condition
                        </v-card-title>

                        <v-card-text>
                          <v-row>
                            <v-col>
                              You can select on the list or enter a new
                              condition:
                              <v-autocomplete
                                v-model="new_condition"
                                label="Select a condition"
                                :items="condition_options"
                              />
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col>
                              <v-textarea
                                v-model="new_condition"
                                label="Enter the condition here..."
                                outlined
                                auto-grow
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-divider />

                        <v-card-actions>
                          <v-btn
                            v-if="adding_condition"
                            text
                            color="primary"
                            @click="handle_save_condition"
                          >
                            save
                          </v-btn>

                          <v-btn
                            v-if="updating_condition"
                            text
                            color="primary"
                            @click="handle_update_condition"
                          >
                            save
                          </v-btn>

                          <v-btn
                            text
                            @click="
                              adding_condition = false;
                              updating_condition = false;
                            "
                          >
                            cancel
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Lender Information -->
              <v-expansion-panel ref="lender_info" class="bordered">
                <v-expansion-panel-header>
                  <div>Lender Information</div>
                </v-expansion-panel-header>

                <v-divider />

                <v-expansion-panel-content>
                  <v-alert
                    class="mt-5"
                    color="orange darken-3"
                    dense
                    transition="fade-transition"
                    outlined
                  >
                    <div class="d-flex align-center">
                      <v-icon color="orange darken-3">mdi-information-outline</v-icon>
                      <span class="ml-2">
                        Any additional for accounts and notes or changes will be saved only if the update button is clicked.
                      </span>
                    </div>
                  </v-alert>

                  <v-row class="mt-5">
                    <v-col>
                      <v-row v-if="fund_mort.int_accounts.length > 0">
                        <v-col>
                          <p class="grey--text">Selected Accounts:</p>

                          <v-simple-table class="mb-5">
                            <thead class="text-caption">
                              <tr>
                                <th>Company Name</th>

                                <th>Account Name</th>

                                <th>Type</th>

                                <th>Category</th>

                                <th>Account Number</th>

                                <th :width="150">Percentage</th>

                                <th :width="50"></th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr
                                v-for="(
                                  int_account, index
                                ) in fund_mort.int_accounts"
                                :key="int_account.account.id"
                              >
                                <td>
                                  {{ int_account.account.company.name }}
                                </td>

                                <td>
                                  {{ int_account.account.name }}
                                </td>

                                <td>
                                  {{ int_account.account.type }}
                                </td>

                                <td>
                                  {{ int_account.account.category }}
                                </td>

                                <td>
                                  {{ int_account.account.account_number }}
                                </td>

                                <td>
                                  <v-text-field
                                    v-model="int_account.percentage"
                                    class="my-2"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    append-icon="mdi-percent-outline"
                                    :rules="[rules.percentage]"
                                    @input="
                                      updatePercentage(int_account, $event)
                                    "
                                    maxlength="3"
                                  />
                                </td>

                                <td>
                                  <v-btn
                                    title="Remove"
                                    icon
                                    @click="deselect_account(int_account.id)"
                                  >
                                    <v-icon color="red">mdi-close</v-icon>
                                  </v-btn>
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>

                          <p
                            :class="
                              fund_mort.int_accounts.reduce(
                                (acc, curr) => acc + Number(curr.percentage),
                                0
                              ) > 100
                                ? 'red--text text-end'
                                : 'white--text text-end'
                            "
                          >
                            Total Percentage:
                            {{
                              fund_mort.int_accounts.reduce(
                                (acc, curr) => acc + Number(curr.percentage),
                                0
                              ) > 100
                                ? "Cannot be greater than 100"
                                : fund_mort.int_accounts.reduce(
                                    (acc, curr) =>
                                      acc + Number(curr.percentage),
                                    0
                                  )
                            }}
                            %
                          </p>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <v-btn
                            v-if="!adding_int_account"
                            @click="adding_int_account = true"
                          >
                            add account
                          </v-btn>

                          <v-card v-if="adding_int_account" class="bordered">
                            <v-card-title>
                              <div>
                                Select to add account

                                <v-btn
                                  icon
                                  title="Sync Latest Data"
                                  @click="read_accounts_data()"
                                  class="ml-2"
                                >
                                  <v-icon> mdi-sync </v-icon>
                                </v-btn>
                              </div>
                            </v-card-title>

                            <v-card-text>
                              <v-text-field
                                v-model="account_search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                              />

                              <v-data-table
                                :headers="account_header"
                                :items="account_options"
                                :search="account_search"
                                :items-per-page="5"
                                @click:row="select_account"
                              >
                                <template
                                  v-slot:[`item.company_name`]="{ item }"
                                >
                                  {{ item.company ? item.company.name : "" }}
                                </template>
                              </v-data-table>
                            </v-card-text>

                            <v-divider />

                            <v-card-actions>
                              <v-btn
                                color="red"
                                @click="adding_int_account = false"
                              >
                                cancel
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row v-if="fund_mort.int_account_notes.length > 0">
                        <v-col>
                          <v-data-table
                            :headers="note_header"
                            :items="fund_mort.int_account_notes"
                            :items-per-page="5"
                            item-key="order"
                            hide-default-footer
                            v-model="selected_int_account_note"
                            show-select
                            @click:row="
                              handle_updating_int_account_note($event)
                            "
                          />

                          <v-btn
                            v-if="selected_int_account_note.length > 0"
                            color="error"
                            class="mt-2"
                            @click="handle_delete_int_account_note"
                          >
                            delete
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <v-btn
                            v-if="
                              !adding_int_account_note &&
                              !updating_int_account_note
                            "
                            @click="adding_int_account_note = true"
                          >
                            add note
                          </v-btn>

                          <v-card
                            v-if="
                              adding_int_account_note ||
                              updating_int_account_note
                            "
                          >
                            <v-card-title>
                              {{ adding_int_account_note ? "Add" : "Update" }}
                              Note
                            </v-card-title>

                            <v-card-text>
                              <v-textarea
                                v-model="new_int_account_note"
                                label="Enter Note"
                                outlined
                                auto-grow
                                hide-details
                              />
                            </v-card-text>

                            <v-divider />

                            <v-card-actions>
                              <v-btn
                                color="primary"
                                :disabled="!new_int_account_note.length > 0"
                                @click="
                                  adding_int_account_note
                                    ? handle_save_int_account_note()
                                    : handle_update_int_account_note()
                                "
                              >
                                save
                              </v-btn>

                              <v-btn
                                @click="
                                  adding_int_account_note = false;
                                  updating_int_account_note = false;
                                "
                              >
                                cancel
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Fees -->
              <v-expansion-panel ref="fees" class="bordered">
                <v-expansion-panel-header>
                  <div>Fees</div>
                </v-expansion-panel-header>

                <v-divider />

                <v-expansion-panel-content>
                  <v-alert
                    class="mt-5"
                    color="orange darken-3"
                    dense
                    transition="fade-transition"
                    outlined
                  >
                    <div class="d-flex align-center">
                      <v-icon color="orange darken-3">mdi-information-outline</v-icon>
                      <span class="ml-2">
                        Any additional or changes in fees will be saved only if the update button is clicked.
                      </span>
                    </div>
                  </v-alert>

                  <v-data-table
                    v-if="fund_mort.fund_mort_fees.length !== 0"
                    class="mt-5"
                    :headers="fee_header"
                    item-key="order"
                    :items="fund_mort.fund_mort_fees"
                    :items-per-page="5"
                    @click:row="updateFee"
                    v-model="selected_fee"
                    show-select
                  >
                    <template v-slot:[`item.type`]="{ item }">
                      {{
                        item.type === "others" ? item.specify_others : item.type
                      }}
                    </template>

                    <template v-slot:[`item.amount`]="{ item }">
                      {{ formatCurrency(item.amount.toString()) }}
                    </template>
                  </v-data-table>

                  <v-btn
                    v-if="selected_fee.length > 0"
                    @click="handle_delete_fee"
                    color="error"
                    class="mt-2"
                  >
                    delete fee
                  </v-btn>

                  <v-row v-if="!is_adding_fee">
                    <v-col>
                      <v-btn class="mt-5" @click="is_adding_fee = true">
                        add fee
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-card v-if="is_adding_fee || is_updating_fee" class="mt-5">
                    <v-card-title>
                      {{ is_adding_fee ? "Add" : "Update" }} Fee
                    </v-card-title>

                    <v-divider />

                    <v-card-text>
                      <v-row>
                        <v-col>
                          <v-select
                            v-model="selected_fee_type"
                            :items="fee_types"
                            label="Fee Type"
                            outlined
                            dense
                            hide-details
                          />
                        </v-col>

                        <v-col v-if="selected_fee_type === 'others'">
                          <v-text-field
                            v-model="specify_others"
                            label="Plase specify"
                            outlined
                            hide-details
                            dense
                          />
                        </v-col>

                        <v-col>
                          <v-text-field
                            v-model="fee_amount"
                            label="amount"
                            outlined
                            hide-details
                            dense
                            @blur="fee_amount = formatCurrency(fee_amount)"
                          />
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <v-textarea
                            v-model="fee_note"
                            label="Note"
                            outlined
                            hide-details
                            auto-grow
                          />
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <v-switch
                            class="mt-0"
                            label="included in APR"
                            v-model="fee_included_in_APR"
                            hide-details
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn
                        v-if="is_adding_fee"
                        @click="handle_save_fee_btn"
                        color="primary"
                      >
                        save
                      </v-btn>

                      <v-btn
                        v-if="is_updating_fee"
                        @click="handle_update_fee_btn"
                        color="primary"
                      >
                        update
                      </v-btn>

                      <v-btn
                        class="ml-5"
                        @click="
                          is_adding_fee = false;
                          is_updating_fee = false;
                        "
                      >
                        cancel
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Prepaid Interest -->
              <v-expansion-panel ref="prepaid_interest" class="bordered">
                <v-expansion-panel-header>
                  Prepaid Interest
                </v-expansion-panel-header>

                <v-divider />

                <v-expansion-panel-content>
                  <v-row>
                    <v-col>
                      <v-alert
                        class="mt-5"
                        color="orange darken-3"
                        dense
                        transition="fade-transition"
                        outlined
                      >
                        <div class="d-flex align-center">
                          <v-icon color="orange darken-3">mdi-information-outline</v-icon>
                          <span class="ml-2">
                            Any additional or changes in prepaid interest will be saved only if the update button is clicked.
                          </span>
                        </div>
                      </v-alert>

                      <v-data-table
                        v-if="fund_mort.prep_ints.length > 0"
                        :headers="prep_int_header"
                        :items="fund_mort.prep_ints"
                        :items-per-page="5"
                        item-key="order"
                        hide-default-footer
                        v-model="selected_prep_ints"
                        show-select
                        @click:row="handle_updating_prep_int"
                      >
                        <template v-slot:[`item.length`]="{ item }">
                          {{ item.length }}
                          {{ item.length > 1 ? "months" : "month" }}
                        </template>

                        <template v-slot:[`item.start_date`]="{ item }">
                          {{ item.start_date | formatDate }}
                        </template>

                        <template v-slot:[`item.end_date`]="{ item }">
                          {{ item.end_date | formatDate }}
                        </template>
                      </v-data-table>

                      <v-btn
                        v-if="selected_prep_ints.length > 0"
                        color="error"
                        class="mt-2"
                        @click="handle_delete_prep_int"
                      >
                        delete
                      </v-btn>

                      <br />

                      <v-btn
                        class="mt-2"
                        v-if="!adding_prep_int && !updating_prep_int"
                        @click="adding_prep_int = true"
                      >
                        add interest
                      </v-btn>

                      <v-form
                        v-if="adding_prep_int || updating_prep_int"
                        v-model="prep_int_is_valid"
                      >
                        <v-card>
                          <v-card-title class="text-body-2">
                            {{ adding_prep_int ? "Adding" : "Updating" }}
                            Interest
                          </v-card-title>

                          <v-divider />

                          <v-card-text>
                            <v-row class="mt-5">
                              <v-col>
                                <v-text-field
                                  v-model="new_prep_int_amount"
                                  label="Amount"
                                  outlined
                                  hide-details="auto"
                                  :rules="[rules.required]"
                                  @blur="
                                    new_prep_int_amount =
                                      formatCurrency(new_prep_int_amount)
                                  "
                                />
                              </v-col>

                              <v-col>
                                <v-text-field
                                  v-model="new_prep_int_length"
                                  label="Length (in month)"
                                  type="number"
                                  outlined
                                  hide-details="auto"
                                  :rules="[rules.required]"
                                  @blur="
                                    fund_mort.amortization_months === ''
                                      ? (fund_mort.amortization_months = null)
                                      : (fund_mort.amortization_months = fund_mort.amortization_months)
                                  "
                                />
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-menu
                                  v-model="new_prep_int_start_date_menu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="new_prep_int_start_date"
                                      label="Start Date"
                                      outlined
                                      hide-details="auto"
                                      v-bind="attrs"
                                      v-on="on"
                                      :rules="[rules.date]"
                                      hint="YYYY-MM-DD format"
                                      append-icon="mdi-calendar-blank"
                                    />
                                  </template>
                                  <v-date-picker
                                    no-title
                                    v-model="new_prep_int_start_date"
                                    @input="
                                      new_prep_int_start_date_menu = false
                                    "
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>

                              <v-col>
                                <v-menu
                                  v-model="new_prep_int_end_date_menu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="new_prep_int_end_date"
                                      label="End Date"
                                      outlined
                                      hide-details="auto"
                                      v-bind="attrs"
                                      v-on="on"
                                      :rules="[rules.date]"
                                      hint="YYYY-MM-DD format"
                                      append-icon="mdi-calendar-blank"
                                    />
                                  </template>
                                  <v-date-picker
                                    no-title
                                    v-model="new_prep_int_end_date"
                                    @input="new_prep_int_end_date_menu = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-textarea
                                  v-model="new_prep_int_note"
                                  label="Notes"
                                  auto-grow
                                  outlined
                                  hide-details="auto"
                                  :rules="[rules.required]"
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>

                          <v-card-actions>
                            <v-btn
                              color="primary"
                              :disabled="!prep_int_is_valid"
                              @click="
                                adding_prep_int
                                  ? handle_save_prep_int()
                                  : handle_update_prep_int()
                              "
                            >
                              save
                            </v-btn>

                            <v-btn
                              @click="
                                adding_prep_int = false;
                                updating_prep_int = false;
                              "
                            >
                              cancel
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Payment Methods -->
              <v-expansion-panel ref="payment_method" class="bordered">
                <v-expansion-panel-header>
                  <div>Payment Method</div>
                </v-expansion-panel-header>

                <v-divider />

                <v-expansion-panel-content>
                  <v-alert
                    class="mt-5"
                    color="orange darken-3"
                    dense
                    transition="fade-transition"
                    outlined
                  >
                    <div class="d-flex align-center">
                      <v-icon color="orange darken-3">mdi-information-outline</v-icon>
                      <span class="ml-2">
                        Any additional or changes in payment method will be saved only if the update button is clicked.
                      </span>
                    </div>
                  </v-alert>

                  <v-data-table
                    v-if="fund_mort.pay_mtds.length !== 0"
                    class="mt-5"
                    :headers="payment_method_header"
                    item-key="order"
                    :items="fund_mort.pay_mtds"
                    :items-per-page="5"
                    @click:row="updatePaymentMethod"
                    v-model="selected_payment_methods"
                    show-select
                  />

                  <v-btn
                    v-if="selected_payment_methods.length > 0"
                    @click="handle_delete_payment_method"
                    color="error"
                    class="mt-2"
                  >
                    delete method
                  </v-btn>

                  <v-row v-if="!is_adding_payment_method">
                    <v-col>
                      <v-btn
                        class="mt-5"
                        @click="is_adding_payment_method = true"
                      >
                        add payment method
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-card
                    v-if="
                      is_adding_payment_method || is_updating_payment_method
                    "
                    class="mt-5"
                  >
                    <v-card-title>
                      {{ is_adding_payment_method ? "Add" : "Update" }} payment
                      method
                    </v-card-title>

                    <v-divider />

                    <v-card-text>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="new_payment_method_name"
                            label="Name"
                            outlined
                            hide-details
                            dense
                          />
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <v-textarea
                            v-model="new_payment_method_note"
                            label="Note"
                            outlined
                            hide-details
                            auto-grow
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn
                        v-if="is_adding_payment_method"
                        @click="handle_save_payment_method_btn"
                        color="primary"
                      >
                        save
                      </v-btn>

                      <v-btn
                        v-if="is_updating_payment_method"
                        @click="handle_update_payment_method_btn"
                        color="primary"
                      >
                        update
                      </v-btn>

                      <v-btn
                        class="ml-5"
                        @click="
                          is_adding_payment_method = false;
                          is_updating_payment_method = false;
                        "
                      >
                        cancel
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Referral Information -->
              <v-expansion-panel ref="referral_info" class="bordered">
                <v-expansion-panel-header>
                  <div>Referral Information</div>
                </v-expansion-panel-header>

                <v-divider />

                <v-expansion-panel-content>
                  <v-row>
                    <v-col>
                      <v-switch
                        v-model="fund_mort.with_referral"
                        label="Referral?"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="fund_mort.with_referral">
                    <v-col>
                      <v-row>
                        <v-col>
                          <v-alert
                            class="mt-5"
                            color="orange darken-3"
                            dense
                            transition="fade-transition"
                            outlined
                          >
                            <div class="d-flex align-center">
                              <v-icon color="orange darken-3">mdi-information-outline</v-icon>
                              <span class="ml-2">
                                Any additional or changes in referral notes will be saved only if the update button is clicked.
                              </span>
                            </div>
                          </v-alert>

                          <v-data-table
                            v-if="fund_mort.referral_notes.length > 0"
                            :headers="referral_note_header"
                            :items="fund_mort.referral_notes"
                            :items-per-page="5"
                            item-key="order"
                            hide-default-footer
                            v-model="selected_referral_note"
                            show-select
                            @click:row="handle_updating_referral_note"
                          />

                          <v-btn
                            v-if="selected_referral_note.length > 0"
                            color="error"
                            class="mt-2"
                            @click="handle_delete_referral_note"
                          >
                            delete
                          </v-btn>

                          <br />

                          <v-btn
                            class="mt-2"
                            v-if="
                              !adding_referral_note && !updating_referral_note
                            "
                            @click="adding_referral_note = true"
                          >
                            add notes
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <v-form v-model="referral_note_is_valid">
                            <v-card
                              v-if="
                                adding_referral_note || updating_referral_note
                              "
                            >
                              <v-card-title>
                                {{
                                  adding_referral_note ? "Adding" : "Updating"
                                }}
                                Referral Note
                              </v-card-title>

                              <v-divider />

                              <v-card-text>
                                <v-textarea
                                  v-model="new_referral_note"
                                  label="Make a note"
                                  auto-grow
                                  outlined
                                  :rules="[rules.required]"
                                />
                              </v-card-text>

                              <v-card-actions>
                                <v-btn
                                  color="primary"
                                  :disabled="!referral_note_is_valid"
                                  @click="
                                    adding_referral_note
                                      ? handle_save_referral_note()
                                      : handle_update_referral_note()
                                  "
                                >
                                  save
                                </v-btn>

                                <v-btn
                                  @click="
                                    adding_referral_note = false;
                                    updating_referral_note = false;
                                  "
                                >
                                  cancel
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-form>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Closing/Funding Information -->
              <v-expansion-panel ref="closing_info" class="bordered">
                <v-expansion-panel-header>
                  <div>Closing/Funding Information</div>
                </v-expansion-panel-header>

                <v-divider />

                <v-expansion-panel-content>
                  <v-form
                    ref="closing_funding_information"
                    v-model="closing_funding_information_is_valid"
                  >
                    <v-row class="mt-5">
                      <v-col>
                        <v-menu
                          v-model="date_closed_menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fund_mort.date_closed"
                              label="Date Closed/Funded"
                              outlined
                              hide-details="auto"
                              v-bind="attrs"
                              v-on="on"
                              :rules="[rules.date]"
                              hint="YYYY-MM-DD format"
                              append-icon="mdi-calendar-blank"
                            />
                          </template>
                          <v-date-picker
                            no-title
                            v-model="fund_mort.date_closed"
                            @input="date_closed_menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col>
                        <v-menu
                          v-model="actual_fund_date_menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fund_mort.actual_fund_date"
                              label="Actual Fund Date"
                              outlined
                              hide-details="auto"
                              v-bind="attrs"
                              v-on="on"
                              :rules="[rules.date]"
                              hint="YYYY-MM-DD format"
                              append-icon="mdi-calendar-blank"
                            />
                          </template>
                          <v-date-picker
                            no-title
                            v-model="fund_mort.actual_fund_date"
                            @input="actual_fund_date_menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="fund_mort.reporting_letter_information"
                          label="Reporting Letter Information, did it differ?"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Reimbursement -->
              <v-expansion-panel ref="reimbursement" class="bordered">
                <v-expansion-panel-header>
                  Reimbursement
                </v-expansion-panel-header>

                <v-divider />

                <v-expansion-panel-content>
                  <v-row class="mt-5">
                    <v-col>
                      <p>Borrower reimbursed any funds?</p>

                      <v-radio-group
                        v-model="fund_mort.borrower_with_reimbursed_funds"
                        :rules="[rules.required]"
                        row
                      >
                        <v-radio label="Yes" value="yes" />
                        <v-radio label="No" value="no" />
                      </v-radio-group>

                      <v-row
                        v-if="
                          fund_mort.borrower_with_reimbursed_funds === 'yes'
                        "
                      >
                        <v-col>
                          <div
                            v-if="fund_mort.borrower_reimbursements.length > 0"
                          >
                            <v-alert
                              class="mt-5"
                              color="orange darken-3"
                              dense
                              transition="fade-transition"
                              outlined
                            >
                              <div class="d-flex align-center">
                                <v-icon color="orange darken-3">mdi-information-outline</v-icon>
                                <span class="ml-2">
                                  Any additional or changes in borrower reimbursements will be saved only if the update button is clicked.
                                </span>
                              </div>
                            </v-alert>

                            <p class="grey--text">Reimbursements:</p>
                            
                            <v-data-table
                              :headers="reimbursement_header"
                              :items="fund_mort.borrower_reimbursements"
                              :items-per-page="5"
                              item-key="order"
                              hide-default-footer
                              v-model="selected_borrower_reimbursements"
                              show-select
                              @click:row="
                                handle_updating_borrower_reimbursement
                              "
                            />

                            <v-btn
                              v-if="selected_borrower_reimbursements.length > 0"
                              color="error"
                              class="mt-2"
                              @click="handle_delete_borrower_reimbursement"
                            >
                              delete
                            </v-btn>
                          </div>

                          <v-btn
                            class="mt-5"
                            v-if="!adding_borrower_reimbursement"
                            @click="adding_borrower_reimbursement = true"
                          >
                            Add Reimbursement
                          </v-btn>
                        </v-col>
                      </v-row>

                      <!-- add / update borrower reimbursement -->
                      <v-row
                        v-if="
                          adding_borrower_reimbursement ||
                          updating_borrower_reimbursement
                        "
                      >
                        <v-col>
                          <v-card>
                            <v-card-title>
                              {{
                                adding_borrower_reimbursement ? "Add" : "Update"
                              }}
                              Borrower Reimbursement
                            </v-card-title>

                            <v-card-text>
                              <v-form v-model="borrower_reimbursement_valid">
                                <v-row>
                                  <v-col>
                                    <v-text-field
                                      v-model="
                                        new_borrower_reimbursement_description
                                      "
                                      label="Description"
                                      outlined
                                      hide-details="auto"
                                      :rules="[rules.required]"
                                    />
                                  </v-col>

                                  <v-col>
                                    <v-text-field
                                      v-model="
                                        new_borrower_reimbursement_amount
                                      "
                                      label="Amount"
                                      outlined
                                      hide-details="auto"
                                      :rules="[rules.required]"
                                      @blur="
                                        new_borrower_reimbursement_amount =
                                          formatCurrency(
                                            new_borrower_reimbursement_amount
                                          )
                                      "
                                    />
                                  </v-col>
                                </v-row>

                                <v-row>
                                  <v-col>
                                    <v-textarea
                                      v-model="new_borrower_reimbursement_note"
                                      label="Make a note (optional)"
                                      auto-grow
                                      outlined
                                    />
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-card-text>

                            <v-card-actions>
                              <v-btn
                                color="primary"
                                @click="
                                  adding_borrower_reimbursement
                                    ? handle_save_borrower_reimbursement()
                                    : handle_update_borrower_reimbursement()
                                "
                                :disabled="!borrower_reimbursement_valid"
                              >
                                save
                              </v-btn>

                              <v-btn
                                class="ml-5"
                                @click="
                                  adding_borrower_reimbursement = false;
                                  updating_borrower_reimbursement = false;
                                "
                              >
                                cancel
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-divider class="mt-5" />

                  <v-row class="mt-5">
                    <v-col>
                      <p>Was the lender reimbursed any funds?</p>

                      <v-radio-group
                        v-model="fund_mort.lender_with_reimbursed_funds"
                        :rules="[rules.required]"
                        row
                      >
                        <v-radio label="Yes" value="yes" />
                        <v-radio label="No" value="no" />
                      </v-radio-group>

                      <v-row
                        v-if="fund_mort.lender_with_reimbursed_funds === 'yes'"
                      >
                        <v-col>
                          <div
                            v-if="fund_mort.lender_reimbursements.length > 0"
                          >
                            <v-alert
                              class="mt-5"
                              color="orange darken-3"
                              dense
                              transition="fade-transition"
                              outlined
                            >
                              <div class="d-flex align-center">
                                <v-icon color="orange darken-3">mdi-information-outline</v-icon>
                                <span class="ml-2">
                                  Any additional or changes in lender reimbursements will be saved only if the update button is clicked.
                                </span>
                              </div>
                            </v-alert>

                            <p class="grey--text">Reimbursements:</p>

                            <v-data-table
                              :headers="reimbursement_header"
                              :items="fund_mort.lender_reimbursements"
                              :items-per-page="5"
                              item-key="order"
                              hide-default-footer
                              v-model="selected_lender_reimbursements"
                              show-select
                              @click:row="handle_updating_lender_reimbursement"
                            />

                            <v-btn
                              v-if="selected_lender_reimbursements.length > 0"
                              color="error"
                              class="mt-2"
                              @click="handle_delete_lender_reimbursement"
                            >
                              delete
                            </v-btn>
                          </div>
                          <v-btn
                            class="mt-5"
                            v-if="!adding_lender_reimbursement"
                            @click="adding_lender_reimbursement = true"
                          >
                            Add Reimbursement
                          </v-btn>
                        </v-col>
                      </v-row>

                      <!-- add / update lender reibursement -->
                      <v-row
                        v-if="
                          adding_lender_reimbursement ||
                          updating_lender_reimbursement
                        "
                      >
                        <v-col>
                          <v-card>
                            <v-card-title>
                              {{
                                adding_lender_reimbursement ? "Add" : "Update"
                              }}
                              Lender Reimbursement
                            </v-card-title>

                            <v-divider />

                            <v-card-text>
                              <v-form v-model="lender_reimbursement_valid">
                                <v-row>
                                  <v-col>
                                    <v-text-field
                                      v-model="
                                        new_lender_reimbursement_description
                                      "
                                      label="description"
                                      outlined
                                      hide-details="auto"
                                      :rules="[rules.required]"
                                    />
                                  </v-col>

                                  <v-col>
                                    <v-text-field
                                      v-model="new_lender_reimbursement_amount"
                                      label="Amount"
                                      outlined
                                      hide-details="auto"
                                      :rules="[rules.required]"
                                      @blur="
                                        new_lender_reimbursement_amount =
                                          formatCurrency(
                                            new_lender_reimbursement_amount
                                          )
                                      "
                                    />
                                  </v-col>
                                </v-row>

                                <v-row>
                                  <v-col>
                                    <v-textarea
                                      v-model="new_lender_reimbursed_note"
                                      label="Make a note (optional)"
                                      auto-grow
                                      outlined
                                    />
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-card-text>

                            <v-card-actions>
                              <v-btn
                                color="primary"
                                @click="
                                  adding_lender_reimbursement
                                    ? handle_save_lender_reimbursement()
                                    : handle_update_lender_reimbursement()
                                "
                                :disabled="!lender_reimbursement_valid"
                              >
                                save
                              </v-btn>

                              <v-btn
                                class="ml-5"
                                @click="
                                  adding_lender_reimbursement = false;
                                  updating_lender_reimbursement = false;
                                "
                              >
                                cancel
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Notes -->
              <v-expansion-panel ref="notes" class="bordered">
                <v-expansion-panel-header> Notes </v-expansion-panel-header>

                <v-divider />

                <v-expansion-panel-content>

                  <!-- note list -->
                  <v-card class="mt-5" style="background: transparent" ref="note_list" flat>
                    <p class="mb-0 grey--text font-weight-medium">
                      Click on the text field to input your notes, you can also add
                      screenshots or image files by copy/paste or drag and drop into
                      the text field below.
                    </p>

                    <v-card-text>
                      <p
                        v-if="fund_mort.notes.length === 0"
                        class="text-center grey--text text-subtitle-1"
                      >
                        No notes available. Make a note below.
                      </p>

                      <div
                        v-for="(note, index) in fund_mort.notes"
                        :key="note.id"
                        class="my-2"
                      >
                        <v-card class="rounded-lg">
                          <v-card-text>
                            <p class="d-flex align-center mb-0">
                              <span>
                                <v-icon class="text-body-1 mr-1">
                                  mdi-account-outline
                                </v-icon>
                                {{ note.user ? note.user.first_name : "" }}
                                {{ note.user ? note.user.last_name : "" }}
                              </span>

                              <span class="ml-auto">
                                <v-icon class="text-body-1 mr-1">
                                  mdi-calendar-outline
                                </v-icon>
                                {{ note.createdAt | formatDate }}
                              </span>

                              <span class="ml-5">
                                <v-btn
                                  title="Click to edit note"
                                  @click="handle_edit_note(note, index)"
                                  icon
                                  small
                                >
                                  <v-icon color="teal" class="text-body-1">
                                    mdi-pencil-outline
                                  </v-icon>
                                </v-btn>

                                <v-btn
                                  title="Click to delete"
                                  @click="handle_delete_note(index)"
                                  icon
                                  small
                                >
                                  <v-icon color="red"> mdi-close </v-icon>
                                </v-btn>
                              </span>
                            </p>
                          </v-card-text>

                          <v-divider />

                          <v-card-text>
                            <div v-if="note.note" style="white-space: pre">
                              {{ note.note }}
                            </div>

                            <div
                              v-if="note.content"
                              class="note-contents"
                              v-html="note.content"
                            ></div>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-card-text>
                  </v-card>
                  
                  <v-alert
                    class="mt-5"
                    color="orange darken-3"
                    dense
                    transition="fade-transition"
                    outlined
                  >
                    <div class="d-flex align-center">
                      <v-icon color="orange darken-3">mdi-information-outline</v-icon>
                      <span class="ml-2">
                        Any additional or changes in this notes will be saved only if the update button is clicked.
                      </span>
                    </div>
                  </v-alert>

                  <!-- note editor -->
                  <v-card
                    style="background: transparent"
                    ref="task_note_editor"
                    flat
                  >
                    <v-card-text class="pa-1">
                      <div>
                        <RichTextEditor
                          ref="fundMortTipTapEditor"
                          type="fund_mort_note"
                          @handleSaveNote="handle_save_note"
                        />
                      </div>
                    </v-card-text>
                  </v-card>

                  <v-dialog v-model="note_dialog" fullscreen>
                    <v-toolbar class="sticky-toolbar orange darken-3" flat>
                      <v-toolbar-title>
                        <v-icon>mdi-image</v-icon> Image Preview
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="note_dialog = false" title="Close">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>

                    <v-card>
                      <v-card-text>
                        <v-img :src="selectedImage" aspect-ratio="1" />
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <!-- Action -->
            <v-row class="mt-5">
              <v-col>
                <v-btn class="primary" @click="handle_submit_funded_mortgage">
                  {{ viewed_as === "create" ? "Submit" : "Update" }}
                </v-btn>

                <v-btn
                  class="ml-5"
                  @click="$router.push(`/dashboard/admin/deal/read/${deal_id}`)"
                >
                  cancel
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import filt from "@/plugins/filters";
import RichTextEditor from "@/components/note/TipTapEditor.vue";

export default {
  name: "FundedMortgageAdd",
  props: ["deal_id", "viewed_as"],

  components: {
    RichTextEditor,
  },

  data: () => ({
    loading: true,
    submitting: false,
    submitted: false,
    error: false,
    form_is_valid: true,
    loading_int_cust: false,

    // date menus
    brokerage_agreement_signed_menu: false,
    commitment_signed_menu: false,
    mort_start_date_menu: false,
    mort_maturity_date_menu: false,
    first_payment_date_menu: false,
    last_payment_date_menu: false,
    interest_adjustment_date_menu: false,
    new_prep_int_start_date_menu: false,
    new_prep_int_end_date_menu: false,
    date_closed_menu: false,
    actual_fund_date_menu: false,

    is_adding_fee: false,
    is_updating_fee: false,

    adding_nsf_fee_note: false,
    updating_nsf_fee_note: false,

    adding_status_note: false,
    updating_status_note: false,

    adding_holdback: false,
    updating_holdback: false,

    adding_condition: false,
    updating_condition: false,

    adding_int_account: false,
    adding_int_account_note: false,
    updating_int_account_note: false,

    adding_prep_int: false,
    updating_prep_int: false,

    is_adding_payment_method: false,
    is_updating_payment_method: false,

    adding_referral_note: false,
    updating_referral_note: false,

    adding_borrower_reimbursement: false,
    updating_borrower_reimbursement: false,

    adding_lender_reimbursement: false,
    updating_lender_reimbursement: false,

    errorMessage: "",

    client_search: "",
    account_search: "",
    search_condition: "",

    new_nsf_fee_note: "",
    new_status_note: "",
    new_status_order: "",

    new_holdback_order: null,
    new_holdback_amount: null,
    new_holdback_description: null,
    new_holdback_note: null,

    new_condition: "",

    new_int_account_note: "",
    new_int_account_note_order: null,

    new_prep_int_order: null,
    new_prep_int_amount: null,
    new_prep_int_length: null,
    new_prep_int_start_date: null,
    new_prep_int_end_date: null,
    new_prep_int_note: null,

    new_payment_method_name: "",
    new_payment_method_note: "",
    new_referral_note: "",
    payment_order: null,
    selected_fee_type: "",
    specify_others: "",

    new_borrower_reimbursement_order: null,
    new_borrower_reimbursement_description: null,
    new_borrower_reimbursement_amount: null,
    new_borrower_reimbursement_note: null,

    new_lender_reimbursement_order: null,
    new_lender_reimbursement_description: null,
    new_lender_reimbursement_amount: null,
    new_lender_reimbursed_note: null,

    search_borrower_lawyers: "",
    search_lender_lawyers: "",
    adding_borrower_lawyer: false,
    adding_lender_lawyer: false,
    new_borrower_lawyer_note: "",
    temp_borrower_lawyer_note_index: null,
    new_lender_lawyer_note: "",
    temp_lender_lawyer_note_index: null,

    note_dialog: false,
    selectedImage: "",
    temp_note_index: null,

    selected_nsf_fee_note: [],
    selected_status_note: [],
    selected_holdback: [],
    selected_condition: [],
    selected_int_account_note: [],
    selected_fee: [],
    selected_prep_ints: [],
    selected_payment_methods: [],
    selected_referral_note: [],
    selected_borrower_reimbursements: [],
    selected_lender_reimbursements: [],
    selected_notes: [],

    // form validations
    general_information_is_valid: false,
    properties_is_valid: false,
    loan_information_is_valid: false,
    status_is_valid: false,
    holdback_is_valid: false,
    lender_information_is_valid: false,
    fee_is_valid: false,
    prep_int_is_valid: false,
    referral_note_is_valid: false,
    closing_funding_information_is_valid: false,
    borrower_reimbursement_valid: false,
    lender_reimbursement_valid: false,
    note_is_valid: false,

    // expansion panels open as default
    panels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],

    // data table headers
    client_header: [
      { text: "First Name", value: "first_name" },
      { text: "Last Name", value: "last_name" },
    ],

    account_header: [
      { text: "Company Name", value: "company_name" },
      { text: "Institution", value: "institution" },
      { text: "Type", value: "type" },
      { text: "Corporation Name", value: "corporation_name" },
      { text: "Account Number", value: "account_number" },
    ],

    property_header: [
      { text: "Street Number", value: "street_number" },
      { text: "Street Name", value: "street_name" },
      { text: "Street Direction", value: "street_direction" },
      { text: "Unit", value: "unit" },
      { text: "Postal Code", value: "postal_code" },
      { text: "City", value: "city" },
      { text: "Province", value: "province" },
    ],

    fee_header: [
      { text: "Type", value: "type" },
      { text: "Amount", value: "amount" },
      { text: "Note", value: "note" },
      { text: "Included in APR", value: "included_in_APR" },
    ],

    prep_int_header: [
      { text: "Amount", value: "amount" },
      { text: "Length (in month)", value: "length" },
      { text: "Start Date", value: "start_date" },
      { text: "End Date", value: "end_date" },
      { text: "Note", value: "note" },
    ],

    payment_method_header: [
      { text: "Name", value: "name" },
      { text: "Note", value: "note" },
    ],

    referral_note_header: [{ text: "Notes", value: "note" }],

    nsf_fee_note_header: [{ text: "Notes", value: "note" }],

    status_note_header: [{ text: "Notes", value: "note" }],

    holdback_header: [
      { text: "Amount", value: "amount" },
      { text: "Description", value: "description" },
      { text: "Notes", value: "note" },
    ],

    condition_header: [{ text: "Condition", value: "condition" }],

    reimbursement_header: [
      { text: "description", value: "description" },
      { text: "Amount", value: "amount" },
      { text: "Notes", value: "note" },
    ],

    note_header: [{ text: "Notes", value: "note" }],

    lawyer_note_headers: [{ text: "Note", value: "note" }],

    lawyer_headers: [
      { text: "Name", value: "first_name" },
      { text: "Email", value: "email" },
      { text: "Phone", value: "phone" },
      { text: "Company", value: "companies" },
    ],

    // select field options
    rental_income_offset_options: [
      "None",
      "Add Percentage to Gross Income",
      "Reduce Rental exp., add bal to Gross Inc.",
    ],
    status_items: ["current", "delinquent", "paid off", "renewed", "etc."],
    loan_type_items: ["new loan", "renewal"],
    prepayment_penalty_items: ["CLOSED", "OPEN", "OPEN after [X] Months"],
    payment_frequency_items: [
      "Monthly",
      "Quarterly",
      "Bi-Weekly",
      "Annually",
      "Other",
    ],
    mortgage_type_items: ["residential", "commercial"],
    classification_items: [
      "Reverse",
      "Sub-Prime",
      "Construction Mortgage",
      "Home Equity Line Of Credit",
      "Qualified Syndicated Mortgage",
      "Non-Qualified Syndicated Mortgage",
      "Equity-Based Lending",
      "First-Time Homebuyer Mortgage",
    ],
    fiscal_year_items: [
      "2019 - 2020",
      "2020 - 2021",
      "2021 - 2022",
      "2022 - 2023",
      "2023 - 2024",
      "2024 - 2025",
      "2025 - 2026",
      "2026 - 2027",
    ],
    ratio_items: ["conventional", "high ratio"],
    amortization_interest_items: ["amortization", "interest only"],
    condition_options: [
      "Appraisal is required on this property",
      "Insurance with lender named loss payable required on this property",
      "Credit check is required on borrower (Note, we can only send credit reports with applicaitons through Filogix)",
      "Review of income documents required by lender",
      "Review of personal income tax documents required by lender",
      "Review of strata/estoppel/condo documents required by lender",
    ],
    payoff_items: ["None", "Prior to Advance", "From Proceeds", "Other"],

    // for creating fees
    fee_order: null,
    fee_included_in_APR: false,
    fee_amount: null,
    fee_note: null,

    fee_types: [
      "placement",
      "legal",
      "broker",
      "lender",
      "ILA",
      "title insurance",
      "insurance binder cost",
      "documentation",
      "loan communication",
      "admin/application",
      "miscellaneous",
      "appraisal",
      "property inspection",
      "appraisal invoice",
      "others",
    ],

    // data from API
    dealData: {},
    account_options: [],
    company_lawyers: [],

    // data models
    fund_mort: {
      // data for general information
      filogix_number: null,
      fiscal_year: null,
      brokerage_agreement_signed: null,
      commitment_signed: null,

      // data for customers
      is_blanket_mortgage: false,
      int_custs: [],
      int_props: [],

      // data for lawyers
      borrower_lyrs: [],
      lender_lyrs: [],

      // data for loan information
      value: 0,
      ltv: 0,
      mortgage_position: 0,

      term_months: 0,
      interest_rate: 0,
      interest_amount: 0,

      gds: 0,
      tds: 0,

      apr_in_disclosure: 0,
      apr_in_formula: 0,

      mort_start_date: null,
      mort_maturity_date: null,
      first_payment_date: null,
      last_payment_date: null,
      interest_adjustment: 0,
      interest_adjustment_date: null,

      loan_type: null,
      mortgage_type: null,
      ratio: null,
      classification: null,

      alert: false,
      include_in_tds: true,
      amortization_interest: null,
      amortization_months: 0,
      nsf_fee_amount: 0,
      nsf_fee_notes: [],
      prepayment_penalty: null,
      prepayment_penalty_number_of_months: 0,
      payment_amount: 0,
      payment_frequency: null,
      specify_payment_frequency: null,

      // data for status
      status: null,
      status_notes: [],

      // data for holdback
      with_holdbacks: false,
      holdbacks: [],

      // data for condition
      fund_mort_conds: [],

      // data for lender information
      accounts: [],
      int_accounts: [],
      int_account_notes: [],

      // data for fees
      fund_mort_fees: [],

      // data for prepayment interest
      prep_ints: [],

      // data for payment methods
      pay_mtds: [],

      // data for referral information
      with_referral: false,
      referral_notes: [],

      // data for closing/funding information
      date_closed: null,
      actual_fund_date: null,
      reporting_letter_information: null,

      // data for reimbursement
      borrower_with_reimbursed_funds: null,
      borrower_reimbursements: [],

      lender_with_reimbursed_funds: null,
      lender_reimbursements: [],

      // data for notes
      notes: [],
    },

    rules: {
      required: (value) => !!value || "Required.",
      number: (value) =>
        value === "" ||
        value === null ||
        /^\d+$/.test(value) ||
        "Must be a number.",
      percentage: (value) =>
        (value >= 0 && value <= 100) || "Input must be between 0 and 100",
      date: (v) =>
        v === null ||
        v === "" ||
        (v && /^\d{4}-\d{2}-\d{2}$/.test(v)) ||
        "Invalid date. Format should be YYYY-MM-DD",
      month: [
        (v) =>
          v === "" || v === null || /^\d+$/.test(v) || "Input must be a number",
        (v) => (v >= 0 && v <= 11) || "Input must be between 0 and 11",
      ],
    },
  }),

  methods: {
    getOffsetTop(element) {
      let offsetTop = 0;
      while (element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
      }
      return offsetTop;
    },

    scrollToElement(element) {
      window.scrollTo({
        top: this.getOffsetTop(element) - 100, // adjust the scroll position by 100px
        behavior: "smooth",
      });
    },

    // method for notes
    handle_save_note(content, is_edit) {
      if (is_edit) {
        this.fund_mort.notes[this.temp_note_index].content = content;
        this.temp_note_index = null;

        const note_list = this.$refs.note_list.$el;
        this.scrollToElement(note_list);
      } else {
        this.fund_mort.notes.push({
          note: "",
          content: content,
          user: {
            id: this.$store.getters["Auth/getAuthUser"].id,
            first_name: this.$store.getters["Auth/getAuthUser"].first_name,
            last_name: this.$store.getters["Auth/getAuthUser"].last_name,
          },
          createdAt: new Date().toISOString(),
        });
      }
    },

    handle_delete_note(index) {
      if (window.confirm("Are you sure you want to delete this note?")) {
        this.fund_mort.notes.splice(index, 1);
      } else {
        console.log("Delete cancelled");
        return;
      }
    },

    handle_edit_note(note, index) {
      this.temp_note_index = index;

      // set the editor content
      this.$refs.fundMortTipTapEditor.setContentToEdit(note);

      const task_note_editor = this.$refs.task_note_editor.$el;
      this.scrollToElement(task_note_editor);
    },

    onNoteImageClick(event) {
      if (event.target.tagName === "IMG") {
        this.selectedImage = event.target.src;
        this.note_dialog = true;
      }
    },

    // method for loan information
    handle_save_nsf_fee_note(nsf_id) {
      if (nsf_id instanceof Event) {
        nsf_id = null;
      }

      let new_order = 0;

      if (this.fund_mort.nsf_fee_notes.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.fund_mort.nsf_fee_notes.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      if (this.new_nsf_fee_note) {
        this.fund_mort.nsf_fee_notes.push({
          order: new_order,
          note: this.new_nsf_fee_note,
          id: nsf_id,
        });

        this.new_nsf_fee_note = "";
        this.adding_nsf_fee_note = false;
      } else {
        alert("Please make a note");
      }
    },

    handle_update_nsf_fee_note() {
      const index = this.fund_mort.nsf_fee_notes.findIndex(
        (note) => note.order === this.new_nsf_fee_order
      );

      this.fund_mort.nsf_fee_notes[index].note = this.new_nsf_fee_note;
      this.new_nsf_fee_note = "";
      this.updating_nsf_fee_note = false;
    },

    handle_updating_nsf_fee_note(data) {
      if (this.adding_nsf_fee_note) {
        alert("Please finish adding note first");
        return;
      }

      this.new_nsf_fee_note = data.note;
      this.new_nsf_fee_order = data.order;
      this.updating_nsf_fee_note = true;
    },

    handle_delete_nsf_fee_note() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_nsf_fee_note.forEach((item) => {
          this.fund_mort.nsf_fee_notes.splice(
            this.fund_mort.nsf_fee_notes.indexOf(item),
            1
          );
        });

        this.selected_nsf_fee_note = [];
      } else {
        console.log("deleting nsf fee note canceled");
      }
    },

    // method for status section
    // handle_save_status_note(status_id) {
    //   if (status_id instanceof Event) {
    //     status_id = null;
    //   }

    //   let new_order = 0;

    //   if (this.fund_mort.status_notes.length === 0) {
    //     new_order = new_order + 1;
    //   } else {
    //     // get the heighest order then add 1
    //     const highest_order = Math.max.apply(
    //       Math,
    //       this.fund_mort.status_notes.map((item) => item.order)
    //     );
    //     new_order = highest_order + 1;
    //   }

    //   if (this.new_status_note) {
    //     this.fund_mort.status_notes.push({
    //       order: new_order,
    //       note: this.new_status_note,
    //       id: status_id,
    //     });

    //     this.new_status_note = "";
    //     this.adding_status_note = false;
    //   } else {
    //     alert("Please make a note");
    //   }
    // },

    // handle_update_status_note() {
    //   const index = this.fund_mort.status_notes.findIndex(
    //     (note) => note.order === this.new_status_order
    //   );

    //   this.fund_mort.status_notes[index].note = this.new_status_note;
    //   this.new_status_note = "";
    //   this.updating_status_note = false;
    // },

    // handle_updating_status_note(data) {
    //   if (this.adding_status_note) {
    //     alert("Please finish adding note first");
    //     return;
    //   }

    //   this.new_status_note = data.note;
    //   this.new_status_order = data.order;
    //   this.updating_status_note = true;
    // },

    // handle_delete_status_note() {
    //   if (window.confirm("Are you sure you want to delete this?")) {
    //     this.selected_status_note.forEach((item) => {
    //       this.fund_mort.status_notes.splice(
    //         this.fund_mort.status_notes.indexOf(item),
    //         1
    //       );
    //     });

    //     this.selected_status_note = [];
    //   } else {
    //     console.log("deleting status note canceled");
    //   }
    // },

    // holdback methods
    handle_save_holdback(holdback_id) {
      if (holdback_id instanceof Event) {
        holdback_id = null;
      }

      let new_order = 0;

      if (this.fund_mort.holdbacks.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.fund_mort.holdbacks.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      if (this.new_holdback_note) {
        this.fund_mort.holdbacks.push({
          order: new_order,
          amount: this.new_holdback_amount,
          description: this.new_holdback_description,
          note: this.new_holdback_note,
          id: holdback_id,
        });

        this.new_holdback_amount = null;
        this.new_holdback_description = null;
        this.new_holdback_note = null;
        this.adding_holdback = false;
      } else {
        alert("Please make a note");
      }
    },

    handle_update_holdback() {
      const index = this.fund_mort.holdbacks.findIndex(
        (holdback) => holdback.order === this.new_holdback_order
      );

      this.fund_mort.holdbacks[index].amount = this.new_holdback_amount;
      this.fund_mort.holdbacks[index].description =
        this.new_holdback_description;
      this.fund_mort.holdbacks[index].note = this.new_holdback_note;

      this.new_holdback_amount = null;
      this.new_holdback_description = null;
      this.new_holdback_note = null;
      this.updating_holdback = false;
    },

    handle_updating_holdback(data) {
      if (this.adding_holdback) {
        alert("Please finish adding holdback first");
        return;
      }

      this.new_holdback_amount = data.amount;
      this.new_holdback_description = data.description;
      this.new_holdback_note = data.note;
      this.new_holdback_order = data.order;
      this.updating_holdback = true;
    },

    handle_delete_holdback() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_holdback.forEach((item) => {
          this.fund_mort.holdbacks.splice(
            this.fund_mort.holdbacks.indexOf(item),
            1
          );
        });

        this.selected_holdback = [];
      } else {
        console.log("deleting holdback note canceled");
      }
    },

    // method for condition section
    handle_save_condition(cond_id) {
      if (cond_id instanceof Event) {
        cond_id = null;
      }

      let new_order = 0;

      if (this.fund_mort.fund_mort_conds.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.fund_mort.fund_mort_conds.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      if (this.new_condition) {
        this.fund_mort.fund_mort_conds.push({
          order: new_order,
          condition: this.new_condition,
          id: cond_id,
        });

        this.new_condition = "";
        this.adding_condition = false;
      } else {
        alert("Please make a note");
      }
    },

    handle_update_condition() {
      const index = this.fund_mort.fund_mort_conds.findIndex(
        (condition) => condition.order === this.new_status_order
      );

      this.fund_mort.fund_mort_conds[index].condition = this.new_condition;
      this.new_condition = "";
      this.updating_condition = false;
    },

    handle_updating_condition(data) {
      if (this.adding_condition) {
        alert("Please finish adding condition first");
        return;
      }
      this.new_condition = data.condition;
      this.new_status_order = data.order;
      this.updating_condition = true;
    },

    handle_delete_condition() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_condition.forEach((item) => {
          this.fund_mort.fund_mort_conds.splice(
            this.fund_mort.fund_mort_conds.indexOf(item),
            1
          );
        });

        this.selected_condition = [];
      } else {
        console.log("deleting condition canceled");
      }
    },

    // method for lender information section
    handle_save_int_account_note(note_id) {
      if (note_id instanceof Event) {
        note_id = null;
      }

      let new_order = 0;

      if (this.fund_mort.int_account_notes.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.fund_mort.int_account_notes.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      if (this.new_int_account_note) {
        this.fund_mort.int_account_notes.push({
          order: new_order,
          note: this.new_int_account_note,
          id: note_id,
        });

        this.new_int_account_note = "";
        this.adding_int_account_note = false;
      } else {
        alert("Please make a note");
      }
    },

    handle_update_int_account_note() {
      const index = this.fund_mort.int_account_notes.findIndex(
        (note) => note.order === this.new_int_account_note_order
      );

      this.fund_mort.int_account_notes[index].note = this.new_int_account_note;
      this.new_int_account_note = "";
      this.updating_int_account_note = false;
    },

    handle_updating_int_account_note(data) {
      if (this.adding_int_account_note) {
        alert("Please finish adding note first");
        return;
      }
      this.new_int_account_note = data.note;
      this.new_int_account_note_order = data.order;
      this.adding_int_account_note = false;
      this.updating_int_account_note = true;
    },

    handle_delete_int_account_note() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_int_account_note.forEach((item) => {
          this.fund_mort.int_account_notes.splice(
            this.fund_mort.int_account_notes.indexOf(item),
            1
          );
        });

        this.selected_int_account_note = [];
      } else {
        console.log("deleting int account note canceled");
      }
    },

    updatePercentage(int_account, event) {
      this.$set(int_account, "percentage", event);
      this.$forceUpdate();
    },

    select_account(item) {
      this.fund_mort.int_accounts.push({
        percentage: null,
        account: item,
      });

      const index = this.account_options.findIndex(
        (account) => account.id === item.id
      );

      if (index !== -1) {
        this.account_options.splice(index, 1);
      }

      this.adding_int_account = false;
    },

    deselect_account(id) {
      if (window.confirm("Are you sure you want to remove this account?")) {
        this.account_options.push(
          this.fund_mort.int_accounts.find((account) => account.id === id)
        );

        const index = this.fund_mort.int_accounts.findIndex(
          (account) => account.id === id
        );

        if (index !== -1) {
          this.fund_mort.int_accounts.splice(index, 1);
        }
      } else {
        console.log("removing canceled");
      }
    },

    // method for fees section
    handle_save_fee_btn(fee_id) {
      if (fee_id instanceof Event) {
        fee_id = null;
      }

      if (this.selected_fee_type && this.fee_amount) {
        // get heighest order in fees array
        let new_order = 0;

        if (this.fund_mort.fund_mort_fees.length === 0) {
          new_order = new_order + 1;
        } else {
          // get the heighest order then add 1
          const highest_order = Math.max.apply(
            Math,
            this.fund_mort.fund_mort_fees.map((item) => item.order)
          );
          new_order = highest_order + 1;
        }

        this.fund_mort.fund_mort_fees.push({
          order: new_order,
          type: this.selected_fee_type,
          specify_others: this.specify_others,
          note: this.fee_note,
          amount: this.fee_amount,
          included_in_APR: this.fee_included_in_APR,
          id: fee_id,
        });

        this.is_adding_fee = false;
        this.selected_fee_type = "";
        this.fee_amount = null;
        this.fee_included_in_APR = false;
        this.specify_others = "";
        this.fee_note = "";
      } else {
        alert("Please fill out all fields");
      }
    },

    updateFee(data) {
      if (this.is_adding_fee) {
        alert("Please finish adding fee first");
        return;
      }

      this.is_updating_fee = true;
      this.selected_fee_type = data.type;
      this.fee_amount = data.amount;
      this.fee_note = data.note;
      this.fee_included_in_APR = data.included_in_APR;
      this.fee_order = data.order;
      this.specify_others = data.specify_others;
    },

    handle_update_fee_btn() {
      if (this.selected_fee_type && this.fee_amount) {
        const index = this.fund_mort.fund_mort_fees.findIndex(
          (fee) => fee.order === this.fee_order
        );

        this.fund_mort.fund_mort_fees[index].type = this.selected_fee_type;
        this.fund_mort.fund_mort_fees[index].amount = this.fee_amount;
        this.fund_mort.fund_mort_fees[index].note = this.fee_note;
        this.fund_mort.fund_mort_fees[index].included_in_APR =
          this.fee_included_in_APR;

        this.is_updating_fee = false;
        this.selected_fee_type = "";
        this.fee_amount = null;
        this.fee_note = "";
        this.fee_included_in_APR = false;
        this.fee_index = null;
      } else {
        alert("Please fill out all fields");
      }
    },

    handle_delete_fee() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_fee.forEach((item) => {
          this.fund_mort.fund_mort_fees.splice(
            this.fund_mort.fund_mort_fees.indexOf(item),
            1
          );
        });

        this.selected_fee = [];
      } else {
        console.log("deleting canceled");
      }
    },

    // method for prepaid interest section
    handle_save_prep_int(prep_int_id) {
      if (prep_int_id instanceof Event) {
        prep_int_id = null;
      }

      // get heighest order in fees array
      let new_order = 0;

      if (this.fund_mort.prep_ints.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.fund_mort.prep_ints.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      this.fund_mort.prep_ints.push({
        order: new_order,
        amount: this.new_prep_int_amount,
        length: this.new_prep_int_length,
        start_date: this.new_prep_int_start_date,
        end_date: this.new_prep_int_end_date,
        note: this.new_prep_int_note,
        id: prep_int_id,
      });

      this.adding_prep_int = false;
      this.new_prep_int_amount = null;
      this.new_prep_int_length = null;
      this.new_prep_int_start_date = null;
      this.new_prep_int_end_date = null;
      this.new_prep_int_note = null;
    },

    handle_updating_prep_int(data) {
      if (this.adding_prep_int) {
        alert("Please finish adding prepayment interest first");
        return;
      }
      this.new_prep_int_order = data.order;
      this.new_prep_int_amount = data.amount;
      this.new_prep_int_length = data.length;
      this.new_prep_int_start_date = data.start_date;
      this.new_prep_int_end_date = data.end_date;
      this.new_prep_int_note = data.note;
      this.adding_prep_int = false;
      this.updating_prep_int = true;
    },

    handle_update_prep_int() {
      const index = this.fund_mort.prep_ints.findIndex(
        (prep_int) => prep_int.order === this.new_prep_int_order
      );

      this.fund_mort.prep_ints[index].amount = this.new_prep_int_amount;
      this.fund_mort.prep_ints[index].length = this.new_prep_int_length;
      this.fund_mort.prep_ints[index].start_date = this.new_prep_int_start_date;
      this.fund_mort.prep_ints[index].end_date = this.new_prep_int_end_date;
      this.fund_mort.prep_ints[index].note = this.new_prep_int_note;

      this.new_prep_int_order = null;
      this.new_prep_int_amount = null;
      this.new_prep_int_length = null;
      this.new_prep_int_start_date = null;
      this.new_prep_int_end_date = null;
      this.new_prep_int_note = null;
      this.updating_prep_int = false;
    },

    handle_delete_prep_int() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_prep_ints.forEach((item) => {
          this.fund_mort.prep_ints.splice(
            this.fund_mort.prep_ints.indexOf(item),
            1
          );
        });

        this.selected_prep_ints = [];
      } else {
        console.log("deleting canceled");
      }
    },

    // method for payment method section
    handle_save_payment_method_btn(pay_mtd_id) {
      if (pay_mtd_id instanceof Event) {
        pay_mtd_id = null;
      }

      if (this.new_payment_method_name) {
        // get heighest order in fees array
        let new_order = 0;

        if (this.fund_mort.pay_mtds.length === 0) {
          new_order = new_order + 1;
        } else {
          // get the heighest order then add 1
          const highest_order = Math.max.apply(
            Math,
            this.fund_mort.pay_mtds.map((item) => item.order)
          );
          new_order = highest_order + 1;
        }

        this.fund_mort.pay_mtds.push({
          order: new_order,
          name: this.new_payment_method_name,
          note: this.new_payment_method_note,
          id: pay_mtd_id,
        });

        this.is_adding_payment_method = false;
        this.new_payment_method_name = "";
        this.new_payment_method_note = "";
      } else {
        alert("Please fill in the name of method");
      }
    },

    updatePaymentMethod(data) {
      if (this.is_adding_payment_method) {
        alert("Please finish adding payment method first");
        return;
      }
      this.is_updating_payment_method = true;
      this.new_payment_method_name = data.name;
      this.new_payment_method_note = data.note;
      this.payment_order = data.order; // for updating
    },

    handle_update_payment_method_btn() {
      if (this.new_payment_method_name) {
        const index = this.fund_mort.pay_mtds.findIndex(
          (method) => method.order === this.payment_order
        );

        this.fund_mort.pay_mtds[index].name = this.new_payment_method_name;
        this.fund_mort.pay_mtds[index].note = this.new_payment_method_note;

        this.is_updating_payment_method = false;
        this.new_payment_method_name = "";
        this.new_payment_method_note = "";
        this.payment_order = null;
      } else {
        alert("Please fill in the name of method");
      }
    },

    handle_delete_payment_method() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_payment_methods.forEach((item) => {
          this.fund_mort.pay_mtds.splice(
            this.fund_mort.pay_mtds.indexOf(item),
            1
          );
        });

        this.selected_payment_methods = [];
      } else {
        console.log("deleting canceled");
      }
    },

    // method for referral section
    handle_save_referral_note(note_id) {
      if (note_id instanceof Event) {
        note_id = null;
      }

      let new_order = 0;

      if (this.fund_mort.referral_notes.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.fund_mort.referral_notes.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      if (this.new_referral_note) {
        this.fund_mort.referral_notes.push({
          order: new_order,
          note: this.new_referral_note,
          id: note_id,
        });

        this.new_referral_note = "";
        this.adding_referral_note = false;
      } else {
        alert("Please make a note");
      }
    },

    handle_update_referral_note() {
      const index = this.fund_mort.referral_notes.findIndex(
        (note) => note.order === this.new_status_order
      );

      this.fund_mort.referral_notes[index].note = this.new_referral_note;
      this.new_referral_note = "";
      this.updating_referral_note = false;
    },

    handle_updating_referral_note(data) {
      if (this.adding_referral_note) {
        alert("Please finish adding note first");
        return;
      }
      this.new_referral_note = data.note;
      this.new_status_order = data.order;
      this.adding_referral_note = false;
      this.updating_referral_note = true;
    },

    handle_delete_referral_note() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_referral_note.forEach((item) => {
          this.fund_mort.referral_notes.splice(
            this.fund_mort.referral_notes.indexOf(item),
            1
          );
        });

        this.selected_referral_note = [];
      } else {
        console.log("deleting referral note canceled");
      }
    },

    // method for borrower reimbursement section
    handle_save_borrower_reimbursement(reimbursed_id) {
      if (reimbursed_id instanceof Event) {
        reimbursed_id = null;
      }

      let new_order = 0;

      if (this.fund_mort.borrower_reimbursements.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.fund_mort.borrower_reimbursements.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      this.fund_mort.borrower_reimbursements.push({
        order: new_order,
        description: this.new_borrower_reimbursement_description,
        amount: this.new_borrower_reimbursement_amount,
        note: this.new_borrower_reimbursement_note,
        type: "borrower",
        id: reimbursed_id,
      });

      this.new_borrower_reimbursement_amount = null;
      this.new_borrower_reimbursement_description = null;
      this.new_borrower_reimbursement_note = null;
      this.adding_borrower_reimbursement = false;
    },

    handle_updating_borrower_reimbursement(data) {
      if (this.adding_borrower_reimbursement) {
        alert("Please finish adding borrower reimbursement first");
        return;
      }
      this.new_borrower_reimbursement_description = data.description;
      this.new_borrower_reimbursement_amount = data.amount;
      this.new_borrower_reimbursement_note = data.note;
      this.new_borrower_reimbursement_order = data.order;
      this.adding_borrower_reimbursement = false;
      this.updating_borrower_reimbursement = true;
    },

    handle_update_borrower_reimbursement() {
      const index = this.fund_mort.borrower_reimbursements.findIndex(
        (reimbursement) =>
          reimbursement.order === this.new_borrower_reimbursement_order
      );

      this.fund_mort.borrower_reimbursements[index].description =
        this.new_borrower_reimbursement_description;
      this.fund_mort.borrower_reimbursements[index].amount =
        this.new_borrower_reimbursement_amount;
      this.fund_mort.borrower_reimbursements[index].note =
        this.new_borrower_reimbursement_note;

      this.new_borrower_reimbursement_amount = null;
      this.new_borrower_reimbursement_description = null;
      this.new_borrower_reimbursement_note = null;
      this.adding_borrower_reimbursement = false;
      this.updating_borrower_reimbursement = false;
    },

    handle_delete_borrower_reimbursement() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_borrower_reimbursements.forEach((item) => {
          this.fund_mort.borrower_reimbursements.splice(
            this.fund_mort.borrower_reimbursements.indexOf(item),
            1
          );
        });

        this.selected_borrower_reimbursements = [];
      } else {
        console.log("deleting borrower reimbursement canceled");
      }
    },

    // method for lender reimbursement section
    handle_save_lender_reimbursement(reimbursed_id) {
      if (reimbursed_id instanceof Event) {
        reimbursed_id = null;
      }

      let new_order = 0;

      if (this.fund_mort.lender_reimbursements.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.fund_mort.lender_reimbursements.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      this.fund_mort.lender_reimbursements.push({
        order: new_order,
        description: this.new_lender_reimbursement_description,
        amount: this.new_lender_reimbursement_amount,
        note: this.new_lender_reimbursed_note,
        type: "lender",
        id: reimbursed_id,
      });

      this.new_lender_reimbursement_amount = null;
      this.new_lender_reimbursement_description = null;
      this.new_lender_reimbursed_note = null;
      this.adding_lender_reimbursement = false;
    },

    handle_updating_lender_reimbursement(data) {
      if (this.adding_lender_reimbursement) {
        alert("Please finish adding lender reimbursement first");
        return;
      }
      this.new_lender_reimbursement_description = data.description;
      this.new_lender_reimbursement_amount = data.amount;
      this.new_lender_reimbursed_note = data.note;
      this.new_lender_reimbursement_order = data.order;
      this.adding_lender_reimbursement = false;
      this.updating_lender_reimbursement = true;
    },

    handle_update_lender_reimbursement() {
      const index = this.fund_mort.lender_reimbursements.findIndex(
        (reimbursement) =>
          reimbursement.order === this.new_lender_reimbursement_order
      );

      this.fund_mort.lender_reimbursements[index].description =
        this.new_lender_reimbursement_description;
      this.fund_mort.lender_reimbursements[index].amount =
        this.new_lender_reimbursement_amount;
      this.fund_mort.lender_reimbursements[index].note =
        this.new_lender_reimbursed_note;

      this.new_lender_reimbursement_amount = null;
      this.new_lender_reimbursement_description = null;
      this.new_lender_reimbursed_note = null;
      this.adding_lender_reimbursement = false;
      this.updating_lender_reimbursement = false;
    },

    handle_delete_lender_reimbursement() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_lender_reimbursements.forEach((item) => {
          this.fund_mort.lender_reimbursements.splice(
            this.fund_mort.lender_reimbursements.indexOf(item),
            1
          );
        });

        this.selected_lender_reimbursements = [];
      } else {
        console.log("deleting lender reimbursement canceled");
      }
    },

    toggle_int_cust_main_applicant(id, value) {
      this.fund_mort.int_custs.forEach((int_cust) => {
        if (int_cust.id === id) {
          int_cust.main_applicant = value;
        }
        if (int_cust.id !== id) {
          int_cust.main_applicant = false;
        }
      });
    },

    toggle_int_prop_subject(id, value) {
      this.fund_mort.int_props.forEach((int_prop) => {
        if (int_prop.id === id) {
          int_prop.subject = value;
        }
        if (int_prop.id !== id) {
          int_prop.subject = false;
        }
      });
    },

    // lawyer methods
    handle_add_lawyer(data, type, notes, lyr_id) {
      if (lyr_id instanceof Event) {
        lyr_id = null;
      }

      let new_order = 0;

      if (type === "borrower") {
        if (this.fund_mort.borrower_lyrs.length === 0) {
          new_order = new_order + 1;
        } else {
          // get the heighest order then add 1
          const highest_order = Math.max.apply(
            Math,
            this.fund_mort.borrower_lyrs.map((item) => item.order)
          );
          new_order = highest_order + 1;
        }

        this.fund_mort.borrower_lyrs.push({
          active: true,
          archived: false,
          user: data,
          type: type,
          notes: [],
          adding_note: false,
          updating_note: false,
          selected_notes: [],
          order: new_order,
          id: lyr_id,
        });

        if (notes) {
          notes.forEach((note) => {
            this.new_borrower_lawyer_note = note.note;
            this.handle_save_lyr_note(new_order, type, note.id);
          });
        }

        this.adding_borrower_lawyer = false;
      } else {
        if (this.fund_mort.lender_lyrs.length === 0) {
          new_order = new_order + 1;
        } else {
          // get the heighest order then add 1
          const highest_order = Math.max.apply(
            Math,
            this.fund_mort.lender_lyrs.map((item) => item.order)
          );
          new_order = highest_order + 1;
        }

        this.fund_mort.lender_lyrs.push({
          active: true,
          archived: false,
          user: data,
          type: type,
          notes: [],
          adding_note: false,
          updating_note: false,
          selected_notes: [],
          order: new_order,
          id: lyr_id,
        });

        if (notes) {
          notes.forEach((note) => {
            this.new_lender_lawyer_note = note.note;
            this.handle_save_lyr_note(new_order, type, note.id);
          });
        }

        this.adding_lender_lawyer = false;
      }
    },

    handle_remove_lawyer(order, type) {
      if (window.confirm("Are you sure you want to remove this lawyer?")) {
        if (type === "borrower") {
          const index = this.fund_mort.borrower_lyrs.findIndex(
            (int_lyr) => int_lyr.order === order
          );

          this.fund_mort.borrower_lyrs.splice(index, 1);
        } else {
          const index = this.fund_mort.lender_lyrs.findIndex(
            (int_lyr) => int_lyr.order === order
          );

          this.fund_mort.lender_lyrs.splice(index, 1);
        }
      } else {
        console.log("removing lawyer canceled");
      }
    },

    handle_archive_lawyer(order, type) {
      if (type == "borrower") {
        const index = this.fund_mort.borrower_lyrs.findIndex(
          (int_lyr) => int_lyr.order === order
        );

        this.fund_mort.borrower_lyrs[index].archived =
          !this.fund_mort.borrower_lyrs[index].archived;
      } else {
        const index = this.fund_mort.lender_lyrs.findIndex(
          (int_lyr) => int_lyr.order === order
        );

        this.fund_mort.lender_lyrs[index].archived =
          !this.fund_mort.lender_lyrs[index].archived;
      }
    },

    handle_save_lyr_note(order, type, note_id) {
      if (note_id instanceof Event) {
        note_id = null;
      }

      let index = null;

      if (type === "borrower") {
        index = this.fund_mort.borrower_lyrs.findIndex(
          (borrower_lyr) => borrower_lyr.order === order
        );
      } else {
        index = this.fund_mort.lender_lyrs.findIndex(
          (lender_lyr) => lender_lyr.order === order
        );
      }

      let new_order = 0;

      if (type === "borrower") {
        if (this.fund_mort.borrower_lyrs[index].notes.length === 0) {
          new_order = new_order + 1;
        } else {
          // get the heighest order then add 1
          const highest_order = Math.max.apply(
            Math,
            this.fund_mort.borrower_lyrs[index].notes.map((item) => item.order)
          );
          new_order = highest_order + 1;
        }

        this.fund_mort.borrower_lyrs[index].notes.push({
          order: new_order,
          note: this.new_borrower_lawyer_note,
          id: note_id,
        });
        this.new_borrower_lawyer_note = "";
        this.fund_mort.borrower_lyrs[index].adding_note = false;
        this.fund_mort.borrower_lyrs[index].updating_note = false;
      } else {
        if (this.fund_mort.lender_lyrs[index].notes.length === 0) {
          new_order = new_order + 1;
        } else {
          // get the heighest order then add 1
          const highest_order = Math.max.apply(
            Math,
            this.fund_mort.lender_lyrs[index].notes.map((item) => item.order)
          );
          new_order = highest_order + 1;
        }

        this.fund_mort.lender_lyrs[index].notes.push({
          order: new_order,
          note: this.new_lender_lawyer_note,
          id: note_id,
        });
        this.new_lender_lawyer_note = "";
        this.fund_mort.lender_lyrs[index].adding_note = false;
        this.fund_mort.lender_lyrs[index].updating_note = false;
      }
    },

    handle_update_lawyer_note(data, lyr_index, type) {
      if (type === "borrower") {
        this.temp_borrower_lawyer_note_index = this.fund_mort.borrower_lyrs[
          lyr_index
        ].notes.findIndex((note) => note.order === data.order);

        if (this.fund_mort.borrower_lyrs[lyr_index].adding_note) {
          alert("Please save the note first before updating");
          return;
        }

        this.new_borrower_lawyer_note = data.note;

        this.fund_mort.borrower_lyrs[lyr_index].updating_note = true;
      } else {
        this.temp_lender_lawyer_note_index = this.fund_mort.lender_lyrs[
          lyr_index
        ].notes.findIndex((note) => note.order === data.order);

        if (this.fund_mort.lender_lyrs[lyr_index].adding_note) {
          alert("Please save the note first before updating");
          return;
        }

        this.new_lender_lawyer_note = data.note;

        this.fund_mort.lender_lyrs[lyr_index].updating_note = true;
      }
    },

    handle_save_updated_lyr_note(order, type) {
      let index = null;

      if (type === "borrower") {
        index = this.fund_mort.borrower_lyrs.findIndex(
          (borrower_lyr) => borrower_lyr.order === order
        );
      } else {
        index = this.fund_mort.lender_lyrs.findIndex(
          (lender_lyr) => lender_lyr.order === order
        );
      }

      if (type === "borrower") {
        this.fund_mort.borrower_lyrs[index].notes[
          this.temp_borrower_lawyer_note_index
        ].note = this.new_borrower_lawyer_note;
        this.new_borrower_lawyer_note = "";
        this.fund_mort.borrower_lyrs[index].updating_note = false;
      } else {
        this.fund_mort.lender_lyrs[index].notes[
          this.temp_lender_lawyer_note_index
        ].note = this.new_lender_lawyer_note;
        this.new_lender_lawyer_note = "";
        this.fund_mort.lender_lyrs[index].updating_note = false;
      }
    },

    handle_delete_lawyer_note(order, type) {
      let index = null;

      if (type === "borrower") {
        index = this.fund_mort.borrower_lyrs.findIndex(
          (borrower_lyr) => borrower_lyr.order === order
        );
      } else {
        index = this.fund_mort.lender_lyrs.findIndex(
          (lender_lyr) => lender_lyr.order === order
        );
      }

      if (type === "borrower") {
        if (window.confirm("Are you sure you want to delete this?")) {
          this.fund_mort.borrower_lyrs[index].selected_notes.forEach((item) => {
            this.fund_mort.borrower_lyrs[index].notes.splice(
              this.fund_mort.borrower_lyrs[index].notes.indexOf(item),
              1
            );
          });

          this.fund_mort.borrower_lyrs[index].selected_notes = [];
        } else {
          console.log("deleting note canceled");

          this.fund_mort.borrower_lyrs[index].selected_notes = [];
        }
      } else {
        if (window.confirm("Are you sure you want to delete this?")) {
          this.fund_mort.lender_lyrs[index].selected_notes.forEach((item) => {
            this.fund_mort.lender_lyrs[index].notes.splice(
              this.fund_mort.lender_lyrs[index].notes.indexOf(item),
              1
            );
          });

          this.fund_mort.lender_lyrs[index].selected_notes = [];
        } else {
          console.log("deleting note canceled");

          this.fund_mort.lender_lyrs[index].selected_notes = [];
        }
      }
    },

    formatCompanies(item) {
      return item.companies.map((company) => company.name).join(", ");
    },

    customFilter(value, search, item) {
      return (
        value.toString().toLowerCase().indexOf(search) !== -1 ||
        this.formatCompanies(item).toLowerCase().indexOf(search) !== -1
      );
    },

    formatCurrency(amount) {
      let currencyValue = 0;

      if (amount !== 0 && amount !== null && amount !== undefined) {
        // Remove commas and dollar signs
        let unformattedValue = amount.replace(/[$,]/g, "");

        // Parse the input value as a number
        currencyValue = parseInt(unformattedValue) || 0;

        // Format the number with commas and a dollar sign
        let formatted_amount = currencyValue
          .toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
          .replace(".00", "");

        return formatted_amount;
      } else {
        return 0;
      }
    },

    validate_form() {
      // validate forms

      // general information
      if (!this.$refs.general_information.validate()) {
        alert("Please fill out all required fields at General Information");
        this.submitting = false;
        this.form_is_valid = false;

        if (!this.panels.includes(0)) this.panels.push(0);
        return this.$refs.gen_info.$el.scrollIntoView({
          behavior: "smooth",
        });
      }

      // customer
      const customer_section_is_valid = this.fund_mort.int_custs.some(
        (int_cust) => int_cust.main_applicant === true
      );

      if (!customer_section_is_valid) {
        alert("Please select a main applicant at Customer");
        this.submitting = false;
        this.form_is_valid = false;
        return this.$refs.customers.$el.scrollIntoView({
          behavior: "smooth",
        });
      }

      // property
      const property_section_is_valid = this.fund_mort.int_props.some(
        (int_prop) => int_prop.subject === true
      );

      if (!property_section_is_valid) {
        alert("Please select a subject property at Property");
        this.submitting = false;
        this.form_is_valid = false;

        if (!this.panels.includes(2)) this.panels.push(2);
        return this.$refs.properties.$el.scrollIntoView({
          behavior: "smooth",
        });
      }

      if (!this.panels.includes(2)) this.panels.push(2);

      if (!this.$refs.prop_info.validate()) {
        alert("Please fill out all required fields at Property");
        this.submitting = false;
        this.form_is_valid = false;

        return this.$refs.gen_info.$el.scrollIntoView({
          behavior: "smooth",
        });
      }

      // loan information
      if (!this.panels.includes(6)) this.panels.push(6);

      if (!this.$refs.loan_information.validate()) {
        alert("Please fill out all required fields at Loan Information");
        this.submitting = false;
        this.form_is_valid = false;

        return this.$refs.loan_info.$el.scrollIntoView({
          behavior: "smooth",
        });
      }

      this.form_is_valid = true;
    },

    async handle_submit_funded_mortgage() {
      try {
        this.submitting = true;

        console.log("fund_mort: ", this.fund_mort);

        let response = null;

        this.validate_form();

        if (this.form_is_valid) {
          if (this.viewed_as === "create") {
            response = await API().post(
              `api/funded-mortgage/create_funded_mortgage`,
              {
                deal_id: this.deal_id,
                user_id: this.$store.getters["Auth/getAuthUser"].id,
                fund_mort: this.fund_mort,
              }
            );
          }

          if (this.viewed_as === "edit") {
            response = await API().patch(
              `api/funded-mortgage/update_funded_mortgage`,
              {
                user_id: this.$store.getters["Auth/getAuthUser"].id,
                fund_mort: this.fund_mort,
              }
            );
          }

          if (response.status === 201) {
            this.submitted = true;

            setTimeout(() => {
              this.$router.push(`/dashboard/admin/deal/read/${this.deal_id}`);
              this.submitting = false;
            }, 1000);
          } else {
            this.submitting = false;
            this.error = true;
            this.errorMessage = "Entry was not submitted.";
          }
        }

        this.submitting = false;
      } catch (error) {
        this.submitting = false;
        console.log("Error Message:", error.response.data.message);
        this.error = true;
        this.errorMessage = "Error Message: " + error.response.data.message;
      }
    },

    async read_all_company_lawyer() {
      try {
        const response = await API().get(
          `api/internal-admin/lawyer/read_all_lawyers`
        );

        console.log("company_lawyers: ", response);

        this.company_lawyers = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    async read_accounts_data() {
      try {
        const response = await API().get(`api/funded-mortgage/read_accounts`);
        console.log("accounts: ", response);
        this.account_options = response.data;
      } catch (error) {
        console.log("Error Message:", error.response.data.message);
        this.error = true;
        this.errorMessage = "Error Message: " + error.response.data.message;
      }
    },

    async read_deal_int_custs() {
      if (
        window.confirm(
          "The most recent customer snapshot will be associated in this entry. All data related to the customer will be updated."
        )
      ) {
        try {
          this.loading_int_cust = true;

          // read deal data
          const deal = await API().get(
            `api/internal-admin/dealRead/${this.deal_id}`
          );

          console.log("deal: ", deal);
          this.dealData = deal.data;

          let response = null;

          if (this.viewed_as === "create") {
            response = await API().get(
              `api/internal-admin/deal/read_int_custs?deal_id=${this.deal_id}`
            );

            console.log("deal_int_custs: ", response);

            this.fund_mort.int_custs = response.data;

            this.fund_mort.int_custs.forEach((int_cust) => {
              int_cust.customer.cust_snaps[0].cust_liabs.forEach(
                (cust_liab) => {
                  cust_liab.payoff = "None";
                }
              );
            });
          }

          if (this.viewed_as === "edit") {
            const updated_int_custs = this.dealData.int_custs.filter(
              (int_cust) =>
                this.fund_mort.int_custs
                  .map((item) => item.cust_snap.customer.id)
                  .includes(int_cust.customer.id)
            );

            const new_int_custs = this.dealData.int_custs.filter(
              (int_cust) =>
                !this.fund_mort.int_custs
                  .map((item) => item.cust_snap.customer.id)
                  .includes(int_cust.customer.id)
            );

            const removed_int_custs = this.fund_mort.int_custs.filter(
              (int_cust) =>
                !this.dealData.int_custs
                  .map((item) => item.customer.id)
                  .includes(int_cust.cust_snap.customer.id)
            );

            console.log("updated_int_custs: ", updated_int_custs);
            console.log("new_int_custs: ", new_int_custs);
            console.log("removed_int_custs: ", removed_int_custs);

            // update all updated int_custs to fund_mort.int_custs
            for (const updated_int_cust of updated_int_custs) {
              for (const int_cust of this.fund_mort.int_custs) {
                if (
                  int_cust.cust_snap.customer.id ===
                  updated_int_cust.customer.id
                ) {
                  int_cust.cust_snap = updated_int_cust.customer.cust_snaps[0];
                  int_cust.int_liabs = [];

                  updated_int_cust.customer.cust_liabs.forEach((cust_liab) => {
                    int_cust.int_liabs.push({
                      payoff: "None",
                      cust_liab: cust_liab,
                      cust_snap: updated_int_cust.customer.cust_snaps[0],
                    });
                  });
                }
              }
            }

            // push all new int_custs to fund_mort.int_custs
            new_int_custs.forEach((int_cust) => {
              this.fund_mort.int_custs.push({
                business_for_self: int_cust.business_for_self,
                business_self_notes: int_cust.business_self_notes,
                co_applicant_type: int_cust.co_applicant_type,
                createdAt: int_cust.createdAt,
                cust_snap: int_cust.customer.cust_snaps[0],
                int_liabs: [],
                main_applicant: int_cust.main_applicant,
                poa: int_cust.poa,
                poa_notes: int_cust.poa_notes,
                updatedAt: int_cust.updatedAt,
                var_inc_notes: int_cust.var_inc_notes,
                variable_income: int_cust.variable_income,
              });

              int_cust.customer.cust_snaps[0].cust_liabs.forEach(
                (cust_liab) => {
                  const index = this.fund_mort.int_custs.findIndex(
                    (item) =>
                      item.cust_snap.customer.id === int_cust.customer.id
                  );

                  this.fund_mort.int_custs[index].int_liabs.push({
                    payoff: "None",
                    cust_liab: cust_liab,
                    cust_snap: int_cust.customer.cust_snaps[0],
                  });
                }
              );
            });

            // remove all removed int_custs from fund_mort.int_custs
            removed_int_custs.forEach((int_cust) => {
              const index = this.fund_mort.int_custs.findIndex(
                (item) => item.id === int_cust.id
              );

              this.fund_mort.int_custs.splice(index, 1);
            });
          }

          this.read_deal_int_props(); // also update int_props data

          this.loading_int_cust = false;
        } catch (error) {
          console.log("Error Message:", error);
          this.error = true;
          this.errorMessage = "Error Message: " + error.response.data.message;
          this.loading_int_cust = false;
        }
      } else {
        console.log("sync latest int_cust data was canceled");
      }
    },

    async read_deal_int_props() {
      try {
        let response = null;

        if (this.viewed_as === "create") {
          response = await API().get(
            `api/internal-admin/deal/read_int_props?deal_id=${this.deal_id}`
          );

          console.log("deal_int_props: ", response);

          this.fund_mort.int_props = response.data;

          this.fund_mort.int_props.forEach((int_prop) => {
            int_prop.prop.prop_snaps[0].prop_morts.forEach((prop_mort) => {
              prop_mort.payoff = "None";
            });
          });
        }

        if (this.viewed_as === "edit") {
          const updated_int_props = this.dealData.int_props.filter((int_prop) =>
            this.fund_mort.int_props
              .map((item) => item.prop_snap.prop.id)
              .includes(int_prop.prop.id)
          );

          const new_int_props = this.dealData.int_props.filter(
            (int_prop) =>
              !this.fund_mort.int_props
                .map((item) => item.prop_snap.prop.id)
                .includes(int_prop.prop.id)
          );

          const removed_int_props = this.fund_mort.int_props.filter(
            (int_prop) =>
              !this.dealData.int_props
                .map((item) => item.prop.id)
                .includes(int_prop.prop_snap.prop.id)
          );

          console.log("updated_int_props: ", updated_int_props);

          console.log("new_int_props: ", new_int_props);

          console.log("removed_int_props: ", removed_int_props);

          // update all updated int_props to fund_mort.int_props
          for (const updated_int_prop of updated_int_props) {
            console.log("updated_int_prop: ", updated_int_prop);
            for (const int_prop of this.fund_mort.int_props) {
              if (int_prop.prop_snap.prop.id === updated_int_prop.prop.id) {
                int_prop.subject = updated_int_prop.subject;
                int_prop.rental_income_offset =
                  updated_int_prop.rental_income_offset;
                int_prop.rental_income_offset_percent =
                  updated_int_prop.rental_income_offset_percent;
                int_prop.prop_costs_incl_tds =
                  updated_int_prop.prop_costs_incl_tds === null ? true : updated_int_prop.prop_costs_incl_tds;

                int_prop.prop_snap = updated_int_prop.prop.prop_snaps[0];
                int_prop.int_mtgs = [];

                updated_int_prop.prop.prop_morts.forEach((prop_mort) => {
                  int_prop.int_mtgs.push({
                    payoff: "None",
                    prop_mort: prop_mort,
                    prop_snap: updated_int_prop.prop.prop_snaps[0],
                  });
                });
              }
            }
          }

          // push all new int_props to fund_mort.int_props
          new_int_props.forEach((int_prop) => {
            this.fund_mort.int_props.push({
              prop_snap: int_prop.prop.prop_snaps[0],
              int_mtgs: [],
              subject: int_prop.subject,
              rental_income_offset: int_prop.rental_income_offset,
              rental_income_offset_percent:
                int_prop.rental_income_offset_percent,
              prop_costs_incl_tds: int_prop.prop_costs_incl_tds,
            });

            const index = this.fund_mort.int_props.findIndex(
              (item) => item.prop_snap.prop.id === int_prop.prop.id
            );

            int_prop.prop.prop_morts.forEach((prop_mort) => {
              this.fund_mort.int_props[index].int_mtgs.push({
                payoff: "None",
                int_prop: int_prop,
                prop_mort: prop_mort,
                prop_snap: int_prop.prop.prop_snaps[0],
              });
            });
          });

          // remove all removed int_props from fund_mort.int_props
          removed_int_props.forEach((int_prop) => {
            const index = this.fund_mort.int_props.findIndex(
              (item) => item.id === int_prop.id
            );

            this.fund_mort.int_props.splice(index, 1);
          });
        }
      } catch (error) {
        console.log("Error Message:", error.response.data.message);
        this.error = true;
        this.errorMessage = "Error Message: " + error.response.data.message;
      }
    },

    async getData() {
      try {
        this.loading = false;

        // read deal data
        const deal = await API().get(
          `api/internal-admin/dealRead/${this.deal_id}`
        );

        console.log("deal: ", deal);
        this.dealData = deal.data;

        if (this.viewed_as === "create") {
          if (deal.status === 200) {
            this.fund_mort.int_custs = this.dealData.int_custs;

            this.fund_mort.int_custs.forEach((int_cust) => {
              int_cust.customer.cust_snaps[0].cust_liabs.forEach(
                (cust_liab) => {
                  cust_liab.payoff = "None";
                }
              );
            });

            this.fund_mort.int_props = this.dealData.int_props;

            this.fund_mort.int_props.forEach((int_prop) => {
              int_prop.prop.prop_snaps[0].prop_morts.forEach((prop_mort) => {
                prop_mort.payoff = "None";
              });
            });

            const borrower_lyrs = this.dealData.int_lyrs.filter(
              (el) => el.type == "borrower"
            );

            borrower_lyrs.forEach((borrower_lyr, index) => {
              this.handle_add_lawyer(
                borrower_lyr.user,
                "borrower",
                borrower_lyr.notes
              );
            });

            const lender_lyrs = this.dealData.int_lyrs.filter(
              (el) => el.type == "lender"
            );

            lender_lyrs.forEach((lender_lyr, index) => {
              this.handle_add_lawyer(
                lender_lyr.user,
                "lender",
                lender_lyr.notes
              );
            });

            console.log("fund_mort: ", this.fund_mort);
          }
        }

        if (this.viewed_as === "edit") {
          const fund_mort = await API().get(
            `api/funded-mortgage/read_one_fund_mort?id=${this.$route.query.fund_mort_id}`
          );

          this.store_field_values(fund_mort.data);

          console.log("fund_mort: ", fund_mort);
        }

        // read accounts data
        this.read_accounts_data();

        // read company lawyers
        this.read_all_company_lawyer();
      } catch (error) {
        this.error = true;
        this.errorMessage = "Getting form data " + error.message;
        console.log("Getting form data " + error.message);

        setTimeout(() => {
          this.error = false;
          this.errorMessage = "";
        }, 3000);
      }
    },

    store_field_values(data) {
      // data for general information
      this.fund_mort.id = data.id;
      this.fund_mort.filogix_number = data.filogix_number;
      this.fund_mort.fiscal_year = data.fiscal_year;
      this.fund_mort.brokerage_agreement_signed =
        data.brokerage_agreement_signed;
      this.fund_mort.commitment_signed = data.commitment_signed;

      // data for customers
      this.fund_mort.is_blanket_mortgage = data.is_blanket_mortgage;
      this.fund_mort.int_custs = data.int_custs;
      this.fund_mort.int_props = data.int_props;

      // data for lawyers
      const borrower_lyrs = data.int_lyrs.filter(
        (el) => el.type === "borrower"
      );

      for (const item of borrower_lyrs) {
        this.handle_add_lawyer(item.user, "borrower", item.notes, item.id);
      }

      const lender_lyrs = data.int_lyrs.filter((el) => el.type === "lender");

      for (const item of lender_lyrs) {
        this.handle_add_lawyer(item.user, "borrower", item.notes, item.id);
      }

      // data for loan information
      this.fund_mort.value = this.formatCurrency(String(data.value));
      this.fund_mort.ltv = data.ltv;
      this.fund_mort.mortgage_position = data.mortgage_position;

      this.fund_mort.term_months = data.term_months;
      this.fund_mort.interest_rate = data.interest_rate;
      this.fund_mort.interest_amount = this.formatCurrency(
        String(data.interest_amount)
      );

      this.fund_mort.gds = data.gds;
      this.fund_mort.tds = data.tds;

      this.fund_mort.apr_in_disclosure = data.apr_in_disclosure;
      this.fund_mort.apr_in_formula = data.apr_in_formula;

      this.fund_mort.mort_start_date = data.mort_start_date;
      this.fund_mort.mort_maturity_date = data.mort_maturity_date;
      this.fund_mort.first_payment_date = data.first_payment_date;
      this.fund_mort.last_payment_date = data.last_payment_date;
      this.fund_mort.interest_adjustment = data.interest_adjustment;
      this.fund_mort.interest_adjustment_date = data.interest_adjustment_date;

      this.fund_mort.loan_type = data.loan_type;
      this.fund_mort.mortgage_type = data.mortgage_type;
      this.fund_mort.ratio = data.ratio;
      this.fund_mort.classification = data.classification;
      this.fund_mort.include_in_tds = data.include_in_tds;

      this.fund_mort.alert = data.alert;
      this.fund_mort.amortization_interest = data.amortization_interest;
      this.fund_mort.amortization_months = data.amortization_months;
      this.fund_mort.nsf_fee_amount = this.formatCurrency(
        String(data.nsf_fee_amount)
      );
      this.fund_mort.prepayment_penalty = data.prepayment_penalty;
      this.fund_mort.prepayment_penalty_number_of_months =
        data.prepayment_penalty_number_of_months;
      this.fund_mort.payment_amount = this.formatCurrency(
        String(data.payment_amount)
      );
      this.fund_mort.payment_frequency = data.payment_frequency;
      this.fund_mort.specify_payment_frequency = data.specify_payment_frequency;

      // data for nsf notes
      const nsf_fee_notes = data.notes.filter(
        (el) => el.type === "NSF Fee Note"
      );

      nsf_fee_notes.forEach((note) => {
        this.new_nsf_fee_note = note.note;
        this.handle_save_nsf_fee_note(note.id);
      });

      // data for status
      // this.fund_mort.status = data.status;

      // const status_notes = data.notes.filter(
      //   (el) => el.type === "Funded Mortgage Status"
      // );

      // status_notes.forEach((note) => {
      //   this.new_status_note = note.note;
      //   this.handle_save_status_note(note.id);
      // });

      // data for holdback
      this.fund_mort.with_holdbacks = data.with_holdbacks;

      data.holdbacks.forEach((holdback) => {
        this.new_holdback_amount = this.formatCurrency(String(holdback.amount));
        this.new_holdback_description = holdback.description;
        this.new_holdback_note = holdback.note;
        this.handle_save_holdback(holdback.id);
      });

      // data for condition
      data.fund_mort_conds.forEach((cond) => {
        this.new_condition = cond.condition;
        this.handle_save_condition(cond.id);
      });

      // data for lender information
      this.fund_mort.int_accounts = data.int_accounts;

      data.int_account_notes.forEach((note) => {
        this.new_int_account_note = note.note;
        this.handle_save_int_account_note(note.id);
      });

      // data for fees
      data.fund_mort_fees.forEach((fee) => {
        (this.selected_fee_type = fee.type),
          (this.specify_others = fee.specify_others),
          (this.fee_note = fee.note),
          (this.fee_amount = this.formatCurrency(String(fee.amount))),
          (this.fee_included_in_APR = fee.included_in_APR),
          this.handle_save_fee_btn(fee.id);
      });

      // data for prepayment interest
      data.prep_ints.forEach((prep_int) => {
        (this.new_prep_int_amount = this.formatCurrency(
          String(prep_int.amount)
        )),
          (this.new_prep_int_length = prep_int.length),
          (this.new_prep_int_start_date = prep_int.start_date),
          (this.new_prep_int_end_date = prep_int.end_date),
          (this.new_prep_int_note = prep_int.note),
          this.handle_save_prep_int(prep_int.id);
      });

      // data for payment methods
      data.pay_mtds.forEach((pay_mtd) => {
        (this.new_payment_method_name = pay_mtd.name),
          (this.new_payment_method_note = pay_mtd.note),
          this.handle_save_payment_method_btn(pay_mtd.id);
      });

      // data for referral information
      this.fund_mort.with_referral = data.with_referral;
      const referral_notes = data.notes.filter(
        (el) => el.type === "Referral Note"
      );

      referral_notes.forEach((note) => {
        this.new_referral_note = note.note;
        this.handle_save_referral_note(note.id);
      });

      // data for closing/funding information
      this.fund_mort.date_closed = data.date_closed;
      this.fund_mort.actual_fund_date = data.actual_fund_date;
      this.fund_mort.reporting_letter_information =
        data.reporting_letter_information;

      // data for reimbursement
      this.fund_mort.borrower_with_reimbursed_funds =
        data.borrower_with_reimbursed_funds;

      const borrower_reimbursed = data.reimbursed.filter(
        (el) => el.type === "borrower"
      );

      borrower_reimbursed.forEach((reimbursed) => {
        this.new_borrower_reimbursement_description = reimbursed.description;
        this.new_borrower_reimbursement_amount = this.formatCurrency(
          String(reimbursed.amount)
        );
        this.new_borrower_reimbursement_note = reimbursed.note;
        this.handle_save_borrower_reimbursement(reimbursed.id);
      });

      this.fund_mort.lender_with_reimbursed_funds =
        data.lender_with_reimbursed_funds;

      const lender_reimbursed = data.reimbursed.filter(
        (el) => el.type === "lender"
      );

      lender_reimbursed.forEach((reimbursed) => {
        this.new_lender_reimbursement_description = reimbursed.description;
        this.new_lender_reimbursement_amount = this.formatCurrency(
          String(reimbursed.amount)
        );
        this.new_lender_reimbursed_note = reimbursed.note;
        this.handle_save_lender_reimbursement(reimbursed.id);
      });

      // data for notes
      const notes = data.notes.filter(
        (el) => el.type === "Funded Mortgage Note"
      );

      this.fund_mort.notes = data.notes;
    },
  },

  filters: {
    ...filt,
  },

  created() {
    this.getData();
    
    this.$nextTick(() => {
      if (this.$refs.note_list) {
        this.$refs.note_list.$el.addEventListener(
          "click",
          this.onNoteImageClick
        );
      }
    });
  },
};
</script>

<style lang="scss">
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}

.side-nav {
  background-color: #111 !important;
  color: white !important;
  top: 100px !important;
  position: sticky !important;
  position: -webkit-sticky !important;
}


.note-contents {
  p {
    img {
      max-width: 100%;  
      height: auto;
      cursor: pointer;
    }
  }
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #444;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #444;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}
</style>
